import AboutPolldock from "../components/AboutPolldock";

import classes from "./AboutPolldockPage.module.css"

function AboutPolldockPage() {
  //
  return (
    <div>
      <h1 className={classes.aboutPolldockPage_h1}>About Polldock</h1>
      <div className={classes.aboutPolldockPage_underline}></div>
      <AboutPolldock />
    </div>
  );
}

export default AboutPolldockPage;
