import NavigationBar from "./NavigationBar";
import logoX from "../../images/X_logo.png";
import logoFacebook from "../../images/Facebook_logo.png";
import logoInstagram from "../../images/Instagram_logo.png";

import classes from "./Layout.module.css";

function Layout(props) {
  return (
    <div className={classes.layout_containerDiv}>
      <NavigationBar />
      <main>
        <div className={classes.layout_mainContainer}>
          <div className={classes.layout_headerDiv}>
            <div className={classes.layout_followDiv}>
              <div className={classes.layout_followText}>FOLLOW</div>
              <div className={classes.layout_followIconDiv}>
                <a href="https://twitter.com/PolldockDotCom">
                  <img
                    className={classes.layout_followIconImg}
                    src={logoX}
                    alt="X"
                  />
                </a>
              </div>
              <div className={classes.layout_followIconDiv}>
                <a href="https://www.facebook.com/profile.php?id=61553596394215">
                  <img
                    className={classes.layout_followIconImg}
                    src={logoFacebook}
                    alt="Facebook"
                  />
                </a>
              </div>
              {/* <div className={classes.layout_followIconDiv}>
                <a href="https://www.instagram.com/polldockdotcom/">
                  <img
                    className={classes.layout_followIconImg}
                    src={logoInstagram}
                    alt="Instagram"
                  />
                </a>
              </div> */}
            </div>
          </div>
          {props.children}
        </div>
      </main>
    </div>
  );
}

export default Layout;
