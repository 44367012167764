import { createContext, useState } from "react";

const FilterSortContext = createContext({
  filter: "not answered",
  sort: "new",
  updateFilterSelection: (filterSelection) => {},
  updateSortSelection: (sortSelection) => {},
});

export function FilterSortContextProvider(props) {
  const [filterSelected, setFilterSelected] = useState("not answered");
  const [sortSelected, setSortSelected] = useState("new");

  function updateFilterSelectionHandler(filterSelection) {
    setFilterSelected(filterSelection);
  }

  function updateSortSelectionHandler(sortSelection) {
    setSortSelected(sortSelection);
  }

  const context = {
    filter: filterSelected,
    sort: sortSelected,
    updateFilterSelection: updateFilterSelectionHandler,
    updateSortSelection: updateSortSelectionHandler,
  };

  return (
    <FilterSortContext.Provider value={context}>
      {props.children}
    </FilterSortContext.Provider>
  );
}

export default FilterSortContext;
