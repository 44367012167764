import { Link } from "react-router-dom";

import classes from "./QuestionItem.module.css";

function QuestionItem(props) {
  return (
    <li className={classes.questionItem_li}>
      <Link
        to={
          props.answered
            ? "/question-results/" + props.id + "/"
            : "/answer-question/" + props.id + "/"
        }
        className={classes.questionItem_link}
      >
        <div className={classes.questionItem_div}>{props.question_text}</div>
      </Link>
    </li>
  );
}

export default QuestionItem;
