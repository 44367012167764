import { useContext } from "react";
import FilterSortContext from "../store/filter-sort-context";

import classes from "./FilterSort.module.css";

function FilterSort() {
  const filterSortContext = useContext(FilterSortContext);

  return (
    <div className={classes.filterSort_container1}>
      <div className={classes.filterSort_container2}>
        <div className={classes.filterSort_selectLabel}>Filter questions</div>
        <select
          id="filterSelect"
          className={classes.filterSort_select}
          value={filterSortContext.filter}
          onChange={(e) =>
            filterSortContext.updateFilterSelection(e.target.value)
          }
        >
          <option value="not answered">Not answered</option>
          <option value="answered">Answered</option>
          <option value="all">All</option>
        </select>
      </div>
      <div className={classes.filterSort_container2}>
        <div className={classes.filterSort_selectLabel}>Sort questions</div>
        <select
          id="sortSelect"
          className={classes.filterSort_select}
          value={filterSortContext.sort}
          onChange={(e) =>
            filterSortContext.updateSortSelection(e.target.value)
          }
        >
          <option value="trending">Trending</option>
          <option value="most answered">Most answered</option>
          <option value="new">Newest</option>
          <option value="old">Oldest</option>
        </select>
      </div>
    </div>
  );
}

export default FilterSort;
