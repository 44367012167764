import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function UnansweredQuestionReroute(props) {
  //
  // Set a useNavigate() constant.
  const navigate = useNavigate();

  // Call useEffect() to run each time the component is loaded.
  useEffect(() => {
    //
    // Navigate to the question's answer question page.
    navigate("/answer-question/" + props.q + "/");
  });

  // Return nothing.
  return(null);
}

export default UnansweredQuestionReroute;
