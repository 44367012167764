import { Link } from "react-router-dom";

function ErrorGeneral() {
  //
  // Return an error message.
  return (
    <div>
      <h3>Something went wrong. Please try again later.</h3>
      <Link to="/">Go back to the main page.</Link>
    </div>
  );
}

export default ErrorGeneral;
