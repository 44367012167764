import classes from "./OptionsLegend.module.css";

function OptionsLegend(props) {
  //
  return (
    <div>
      {Array.from(Array(props.optionsCount).keys()).map((j) => (
        <div key={j} className={classes.optionsLegend_rowDiv}>
          <div
            style={{ width: 14, height: 14, backgroundColor: props.colors[j] }}
          ></div>
          <div className={classes.optionsLegend_optionTextDiv}>
            {props.options[j].option_text}
          </div>
        </div>
      ))}
    </div>
  );
}

export default OptionsLegend;
