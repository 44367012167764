import { useState, useEffect, useRef, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import OptionItem from "../components/OptionItem";
import NewUserResponseContext from "../store/new-user-response-context";
import siteName from "../images/site_name.png";
import siteLogo from "../images/site_logo.png";

import classes from "./AnswerQuestionNewUserPage.module.css";

function AnswerQuestionNewUserPage(props) {
  //
  // Get the question_id from the URL.
  let { q } = useParams();

  // Set the NewUserResponseContext to a constant.
  const newUserResponseContext = useContext(NewUserResponseContext);

  // Set a useState() to track whether the page is loading.
  const [isLoading, setIsLoading] = useState(true);

  // Create useRef() instances for the form and the Turnstile widget.
  const answerQuestionFormRef = useRef();

  // Define a useState() to track whether questions and options have been
  //   queried upon loading of the page.
  const [questionLoaded, setQuestionLoaded] = useState(false);

  // Initialize an object to hold the question's ID and text.
  const [question, setQuestion] = useState({});

  // Initialize an array to hold options and their IDs.
  const [options, setOptions] = useState([]);

  // Set a useNavigate() constant.
  const navigate = useNavigate();

  // fetch() the chosen question's id, question_text, option_id, and option_text
  //   values. Put the question's id and question_text into an object.
  //   Iteratively push() each option's id and text, paired as an object, into
  //   an array.
  useEffect(() => {
    fetch("https://prodbe.polldock.com/api/answerOptions/" + q + "/")
    // fetch("http://localhost:8000/api/answerOptions/" + q + "/")
      .then(
        (response) => {
          return response.json();
        },
        () => {
          navigate("/error/", { replace: true });
        }
      )
      .then(
        (dataJson) => {
          const optionsArrayComprehensive = dataJson;
          optionsArrayComprehensive.sort((a, b) => a.option_id - b.option_id);
          const questionObject = {
            question_id: dataJson[0].id,
            question_text: dataJson[0].question_text,
          };
          const optionsArray = [];
          for (const element of optionsArrayComprehensive) {
            optionsArray.push({
              option_id: element.option_id,
              option_text: element.option_text,
            });
          }
          const questionOptionsResponse = {
            questionObject: questionObject,
            optionsArray: optionsArray,
          };
          return questionOptionsResponse;
        },
        () => {
          navigate("/error/", { replace: true });
        }
      )
      .then(
        (qor) => {
          setQuestion(qor.questionObject);
          setOptions(qor.optionsArray);
          setQuestionLoaded(true);
        },
        () => {
          navigate("/error/", { replace: true });
        }
      );
  }, [q, navigate]);

  // Call useEffect() to update isLoading() to false when questionLoaded becomes
  //   true.
  useEffect(() => {
    if (questionLoaded) {
      setIsLoading(false);
    }
  }, [questionLoaded]);

  // Set a useState() constant to track the user's current selection.
  const [currentSelection, setCurrentSelection] = useState(null);

  // Define a handler function that checks if the user has selected an answer
  //   and then posts the response to the database once the user clicks the
  //   submit button, and, finally, navigates to the question's results page.
  function submitAnswerHandler(event) {
    //
    // Prevent the default browser behavior. This is needed to prevent the
    //   browser from reloading upon submit.
    event.preventDefault();

    // Get the currentSelection value at the time of submission.
    const answer = currentSelection;

    // If there is no answer, alert the user. Otherwise, navigate to the page
    //   that prompts the user to enter his info.
    if (answer === null) {
      alert("Please select an answer!");
    } else {
      //
      // Store the question ID and the option ID for the user's answer in
      //   NewUserResponseContext.
      newUserResponseContext.updateQuestionSelection(q);
      newUserResponseContext.updateOptionSelection(answer);

      // Navigate to the new user page so that the user may submit his
      //   information. Once this is done, the user will be able to see the
      //   results for the question answered.
      navigate("/");
    }
  }

  // Define a handler function that sets the value of currentSelection based on
  //   which opiton <div> the user clicks on.
  const optionClickHandler = (optionId) => {
    const selection = optionId;
    setCurrentSelection(selection);
  };

  if (isLoading) {
    return (
      <section>
        <p>Loading...</p>
      </section>
    );
  }

  // Return the answer question elements.
  return (
    <div>
      <header>
        <div className={classes.answerQuestionNewUserPage_titleContainer}>
          <div className={classes.answerQuestionNewUserPage_titleNameDiv}>
            <img
              className={classes.answerQuestionNewUserPage_titleNameImg}
              src={siteName}
              alt={""}
            />
          </div>
          <div className={classes.answerQuestionNewUserPage_titleLogoDiv}>
            <img
              className={classes.answerQuestionNewUserPage_titleLogoImg}
              src={siteLogo}
              alt={""}
            />
          </div>
        </div>
      </header>
      <div className={classes.answerQuestionNewUserPage_mainContainer}>
        <h1 className={classes.answerQuestionNewUserPage_h1}>
          {question.question_text}
        </h1>
        <div className={classes.answerQuestionNewUserPage_underline}></div>
        <form
          id="answerQuestionNewUserForm"
          method="POST"
          onSubmit={submitAnswerHandler}
          ref={answerQuestionFormRef}
        >
          <div>
            <ul className={classes.answerQuestionNewUserPage_ul}>
              {options.map((option) => (
                <OptionItem
                  key={option.option_id}
                  optionId={option.option_id}
                  optionText={option.option_text}
                  optionClickHandler={optionClickHandler}
                  selected={option.option_id === currentSelection} ////
                />
              ))}
            </ul>
          </div>
          <div className={classes.answerQuestionNewUserPage_submitDiv}>
            <button className={classes.answerQuestionNewUserPage_submit}>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AnswerQuestionNewUserPage;
