import { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import NewUserForm from "../components/NewUserForm";
import { getCookie } from "../components/CsrfToken";
import AboutPolldock from "../components/AboutPolldock";
import TermsAndConditions from "../components/TermsAndConditions";
import PrivacyPolicy from "../components/PrivacyPolicy";
import siteName from "../images/site_name.png";
import siteLogo from "../images/site_logo.png";
import dropdownChevron from "../images/dropdown_chevron_black.png";
import NewUserResponseContext from "../store/new-user-response-context";

import classes from "./NewUserPage.module.css";

function NewUserPage() {
  //
  // Create a useNavigate instance.
  const navigate = useNavigate();

  // Set the NewUserResponseContext to a constant.
  const newUserResponseContext = useContext(NewUserResponseContext);

  // Get the question ID and option ID from newUserResponseContext.
  const questionId = newUserResponseContext.question;
  const optionId = newUserResponseContext.option;

  // Get the CSRF token from Django's csrf_token cookie.
  const csrf_token = getCookie("csrftoken");

  // Get the pathname of the page's current location (i.e. the URL in the
  //   browser's address bar).
  const currentLocation = useLocation();
  const clPath = currentLocation.pathname;

  // Define a path to navigate to upon submittal of the NewUserForm. If the
  //   current location is "/home/" or "/home", set our new value equal to the
  //   index "/". Otherwise set it to "/home/". We initially wanted to simply
  //   navigate to "/home/" upon submittal, but when the current location is
  //   "/home/" or "/home" and we are also navigating to "/home/", the page does
  //   not appear to refresh upon submittal. It simply stays on the new user
  //   page (though the new user record does get added to the database).
  var navigateTo;
  if ((clPath === "/home/") | (clPath === "/home")) {
    navigateTo = "/";
  } else {
    navigateTo = "/home/";
  }

  // Define a function to take the new user's ID and the question ID and option
  //   ID for the user's initial response and call the view that writes that
  //   response to the database.
  function writeInitialResponseHandler(uid, qid, oid) {
    //
    // Call the view to post the user's response to the database.
    fetch(
      "https://prodbe.polldock.com/api/newResponseNewUser/" +
      // "http://localhost:8000/api/newResponseNewUser/" +
        uid +
        "/" +
        qid +
        "/" +
        oid +
        "/",
      {
        method: "POST",
        headers: { "X-CSRFToken": csrf_token },
      }
    ).then(
      (response) => {
        //
        // If the fetch() returns successfully, navigate to the question's
        //   results page. Otherwise, navigate to the generic error page. Also,
        //   before navigating, set question and option in the
        //   NewUserResponseContext both to NaN.
        const statusCode = response.status;
        newUserResponseContext.updateQuestionSelection(NaN);
        newUserResponseContext.updateOptionSelection(NaN);
        if (statusCode === 200) {
          navigate("/question-results/" + qid + "/");
        } else {
          navigate("/error/", { replace: true });
        }
      },
      () => {
        navigate("/error/", { replace: true });
      }
    );
  }

  // Define a function to receive the new user info obtained in the new uers
  //   form, create a new record in the User model containing that info,
  //   retrieve the user ID value corresponding to the new record, and store
  //   that new ID value in a cookie.
  function addNewUserHandler(userInfo) {
    //
    // Call the Django REST API view to post the new user record to the database
    //   and to retrieve the new user ID value that is created as a result.
    fetch(
      "https://prodbe.polldock.com/api/newUser/" +
      // "http://localhost:8000/api/newUser/" +
        userInfo.sex +
        "/" +
        userInfo.decade +
        "/" +
        userInfo.location +
        "/" +
        userInfo.turnstileToken +
        "/",
      {
        method: "GET",
        headers: {
          "X-CSRFToken": csrf_token,
        },
        credentials: "include",
      }
    )
      .then(
        (response) => {
          //
          // If the response comes back successfully, return the json() form of
          //   the response data. Otherwise, navigate to the generic error page.
          const statusCode = response.status;
          if (statusCode === 200) {
            // navigate(navigateTo, { replace: true });
            return response.json();
          } else {
            navigate("/error/", { replace: true });
            return null;
          }
        },
        () => {
          navigate("/error/", { replace: true });
        }
      )
      .then(
        (dataJson) => {
          //
          // If the data is populated, extract the new user's ID.
          if (dataJson.length > 0) {
            //
            // Extract the new user's ID.
            const userId = dataJson[0].id;

            // If questionId and optionId are both populated, call the function
            //   to post the user's initial response to the database, and then
            //   navigate to the main question list page. Otherwise, just
            //   navigate to the main question list page.
            if (questionId && optionId) {
              //
              // Call the function to post the user's initial response to the
              //   database.
              writeInitialResponseHandler(userId, questionId, optionId);
            } else {
              //
              // Navigate to the main question list page.
              navigate(navigateTo, { replace: true });
            }
          } else {
            navigate("/error/", { repalce: true });
          }
        },
        () => {
          navigate("/error/", { replace: true });
        }
      );
  }

  // Define a useState() for tracking whether to show or hide the each of the
  //   informational sections.
  const [showAboutPolldock, setShowAboutPolldock] = useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  // Define a function to toggle the value of each of the above useStates() when
  //   the corresponding heading div is clicked.
  const toggleShowAboutPolldock = () => {
    setShowAboutPolldock(!showAboutPolldock);
  };
  const toggleShowTermsAndConditions = () => {
    setShowTermsAndConditions(!showTermsAndConditions);
  };
  const toggleShowPrivacyPolicy = () => {
    setShowPrivacyPolicy(!showPrivacyPolicy);
  };

  // Return components.
  return (
    <div>
      <header>
        <div className={classes.newUserPage_titleContainer}>
          <div className={classes.newUserPage_titleNameDiv}>
            <img
              className={classes.newUserPage_titleNameImg}
              src={siteName}
              alt={""}
            />
          </div>
          <div className={classes.newUserPage_titleLogoDiv}>
            <img
              className={classes.newUserPage_titleLogoImg}
              src={siteLogo}
              alt={""}
            />
          </div>
        </div>
      </header>
      <main>
        <div className={classes.newUserPage_mainContainer}>
          {questionId && optionId ? (
            <div className={classes.newUserPage_viewResultsPromptContainer}>
              <div className={classes.newUserPage_viewResultsPrompt}>
                TO SEE RESULTS, CLICK <strong>SUBMIT</strong> BELOW
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <h1 className={classes.newUserPage_h1}>Welcome!</h1>
          <div className={classes.newUserPage_formDiv}>
            <NewUserForm onAddNewUser={addNewUserHandler} />
          </div>
          <div className={classes.newUserPage_collapseSectionContainer}>
            <div
              className={classes.newUserPage_collapseHeadingDiv}
              onClick={toggleShowAboutPolldock}
            >
              <div className={classes.newUserPage_collapseChevronDiv}>
                <img
                  className={
                    showAboutPolldock
                      ? classes.newUserPage_collapseChevronImgExpanded
                      : classes.newUserPage_collapseChevronImgCollapsed
                  }
                  src={dropdownChevron}
                  alt={""}
                />
              </div>
              <h2 className={classes.newUserPage_collapseHeading}>
                About Polldock
              </h2>
            </div>
            <div
              className={
                showAboutPolldock
                  ? classes.newUserPage_divExpanded
                  : classes.newUserPage_divCollapsed
              }
            >
              <div>
                <AboutPolldock />
              </div>
            </div>
          </div>
          <div className={classes.newUserPage_collapseSectionContainer}>
            <div
              className={classes.newUserPage_collapseHeadingDiv}
              onClick={toggleShowTermsAndConditions}
            >
              <div className={classes.newUserPage_collapseChevronDiv}>
                <img
                  className={
                    showTermsAndConditions
                      ? classes.newUserPage_collapseChevronImgExpanded
                      : classes.newUserPage_collapseChevronImgCollapsed
                  }
                  src={dropdownChevron}
                  alt={""}
                />
              </div>
              <h2 className={classes.newUserPage_collapseHeading}>
                Terms and Conditions
              </h2>
            </div>
            <div
              className={
                showTermsAndConditions
                  ? classes.newUserPage_divExpanded
                  : classes.newUserPage_divCollapsed
              }
            >
              <div>
                <TermsAndConditions />
              </div>
            </div>
          </div>
          <div className={classes.newUserPage_collapseSectionContainer}>
            <div
              className={classes.newUserPage_collapseHeadingDiv}
              onClick={toggleShowPrivacyPolicy}
            >
              <div className={classes.newUserPage_collapseChevronDiv}>
                <img
                  className={
                    showPrivacyPolicy
                      ? classes.newUserPage_collapseChevronImgExpanded
                      : classes.newUserPage_collapseChevronImgCollapsed
                  }
                  src={dropdownChevron}
                  alt={""}
                />
              </div>
              <h2 className={classes.newUserPage_collapseHeading}>
                Privacy Policy
              </h2>
            </div>
            <div
              className={
                showPrivacyPolicy
                  ? classes.newUserPage_divExpanded
                  : classes.newUserPage_divCollapsed
              }
            >
              <div>
                <PrivacyPolicy />
              </div>
            </div>
          </div>
          <div className={classes.newUserPage_bottomBufferContainer}></div>
        </div>
      </main>
    </div>
  );
}

export default NewUserPage;
