import React from "react";

// Define a function to get the CSRF token from the server. This code is taken
//   directly from the Django documentation:
//   https://docs.djangoproject.com/en/3.2/ref/csrf/#ajax
export function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

// Get the CSRF token from Django's csrf_token cookie.
let csrf_token = getCookie("csrftoken");
if (csrf_token === null) {
  csrf_token = "";
}

// Define the React component that will generate the CSRF token.
const CsrfToken = () => {
  //
  // Return a form input element holding the CSRF token. This element is hidden.
  return <input name="csrfmiddlewaretoken" value={csrf_token} type="hidden" />;
};

export default CsrfToken;
