import { createContext, useState } from "react";

// Set the initial value of the userId as well as a dummy function placeholder
//   for the function that will ultimately update the userId.
const UserIdContext = createContext({
  userId: NaN,
  updateUserId: (userIdRetrievedValue) => {},
});

// Create the context provider.
export function UserIdContextProvider(props) {
  //
  // Create a useState() to track the userId retrieved from the cookie.
  const [userIdRetrieved, setUserIdRetrieved] = useState(NaN);

  // Define a handler function for updating the userId.
  function updateUserIdHandler(userIdFromCookie) {
    setUserIdRetrieved(userIdFromCookie);
  }

  // Store userIdRetrieved and updateUserIdHandler to an object to pass to the
  //   context provider.
  const context = {
    userId: userIdRetrieved,
    updateUserId: updateUserIdHandler,
  };

  // Return the context provider.
  return (
    <UserIdContext.Provider value={context}>
      {props.children}
    </UserIdContext.Provider>
  );
}

export default UserIdContext;
