import { useContext } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import * as dfjs from "dataframe-js";
import DimensionValuesContext from "../../store/dimension-values-context";
import OptionsLegend from "./OptionsLegend";

function ResultsOverview(props) {
  //
  // Define an object of parameters taken from the visualization parameters
  //   context.
  const visualizationParameters = useContext(DimensionValuesContext);

  // Store props.resultsDisplay in a variable.
  let resultsDisplayAggPrelim = props.resultsDisplay;

  // Get only the desired columns for aggregating.
  resultsDisplayAggPrelim = resultsDisplayAggPrelim.select(
    "option_id",
    "option_text",
    "response_count"
  );

  // Get a dataframe of all options.
  let optionsPrelim = new dfjs.DataFrame(props.options);

  // Get desired columns for optionsPrelim, option_id and option_text.
  optionsPrelim = optionsPrelim.select("option_id", "option_text");

  // Define a response_count column populated with all 0s.
  optionsPrelim = optionsPrelim.withColumn("response_count", () => 0);

  // Get count of distinct options (which is equal to the number of rows of
  //   optionsPrelim).
  const optionsCount = optionsPrelim.dim()[0];

  // Append optionsPrelim onto resultsDisplayAggPrelim.
  resultsDisplayAggPrelim = resultsDisplayAggPrelim.union(optionsPrelim);

  // Aggregate response_count in resultsDisplay, grouping by option_id,
  //   option_text.
  resultsDisplayAggPrelim = resultsDisplayAggPrelim
    .groupBy("option_id", "option_text")
    .aggregate((group) => group.stat.sum("response_count"))
    .rename("aggregation", "response_count");

  // Sort resultsDisplayAgg by option_id.
  resultsDisplayAggPrelim = resultsDisplayAggPrelim.sortBy("option_id");

  // Get only the desired columns for the data to be displayed.
  resultsDisplayAggPrelim = resultsDisplayAggPrelim.select(
    "option_text",
    "response_count"
  );

  // Convert to an array object.
  let resultsDisplayAgg = resultsDisplayAggPrelim.toCollection();

  // Get the sum of the response_count column.
  const responseCountSum = resultsDisplayAgg.reduce(
    (accumulator, obj) => accumulator + obj.response_count,
    0
  );

  // Convert response_count in each row resultsDisplayAgg to the decimal
  //   distribution out of all responses.
  resultsDisplayAgg = resultsDisplayAgg.map((obj) => {
    let newObj = {};
    newObj.option_text = obj.option_text;
    newObj.response_pct = obj.response_count / responseCountSum;
    return newObj;
  });

  // Get the maximum response_pct value in resultsDisplayAgg.
  const responsePctMax = resultsDisplayAgg.reduce((prevMax, obj) => {
    return obj.response_pct > prevMax ? obj.response_pct : prevMax;
  }, 0);

  // Round responsePctMax up to the nearest decimal that is more than 0.02
  //   greater than responsePctMax. This value will be used to set the domain of
  //   the x-axis of the horizontal bar chart below.
  const responsePctUpperBound =
    (Math.floor((responsePctMax + 0.02 + Number.EPSILON) * 10) + 1) / 10;

  // Define an array of tick values for the x-axis of the horizontal bar chart.
  //   The values will be multiples of 10% from 10% up to
  //   reasponsePctUpperBound.
  let xAxisTicks = [];
  let tickTracker = 10 * responsePctUpperBound;
  while (tickTracker > 0) {
    let newTick = 10 * responsePctUpperBound - tickTracker + 1;
    xAxisTicks.push(newTick / 10);
    tickTracker = tickTracker - 1;
  }

  // Define a function to generate a customized bar label.
  const renderCustomizedLabel = (props) => {
    //
    // Get the props from the LabelList component.
    const { x, y, width, height, value } = props;

    // Return an SVG element containing the label value.
    return (
      <g>
        <text
          x={x + width + 3}
          y={y + height / 2 + 1}
          fill="#000000"
          textAnchor="right"
          dominantBaseline="middle"
        >
          {(100 * value).toFixed(0) + "%"}
        </text>
      </g>
    );
  };

  // Return components.
  return (
    <div>
      <OptionsLegend
        options={props.options}
        optionsCount={optionsCount}
        colors={visualizationParameters.colors}
      />
      <ResponsiveContainer width={"100%"} height={65 * optionsCount}>
        <BarChart
          data={resultsDisplayAgg}
          layout="vertical"
          margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} />
          <XAxis
            type="number"
            ticks={xAxisTicks}
            tickFormatter={(value) => (100 * value).toFixed(0) + "%"}
            domain={[0, responsePctUpperBound]}
          />
          <YAxis
            type="category"
            dataKey="option_text"
            width={20}
            tickLine={false}
            tickFormatter={(value) => ""} /* Hide values in y-axis ticks */
          />
          <Bar dataKey="response_pct" barSize={30} isAnimationActive={false}>
            {Array.from(Array(optionsCount).keys()).map((i) => (
              <Cell
                key={"cell-" + i}
                fill={visualizationParameters.colors[i]}
              />
            ))}
            <LabelList
              dataKey="response_pct"
              content={renderCustomizedLabel}
              position="right"
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ResultsOverview;
