import { useState, useContext, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import CategoryNameContext from "../../store/category-name-context";
import siteName from "../../images/site_name.png";
import siteLogo from "../../images/site_logo.png";
import dropdownChevron from "../../images/dropdown_chevron_gray.png";
import hamburgerOpen from "../../images/hamburger_open.png";
import hamburgerClose from "../../images/hamburger_close.png";

import classes from "./NavigationBar.module.css";

function NavigationBar() {
  //
  // Create a useContext() to get the list of category names.
  const categoryName = useContext(CategoryNameContext);

  // Create a useState() to track the category dropdown CSS class to be passed
  //   to the dropdown element. Do the same for the about dropdown.
  const [categoryDropdownClass, setCategoryDropdownClass] = useState(
    classes.navigationBar_ul2
  );
  const [aboutDropdownClass, setAboutDropdownClass] = useState(
    classes.navigationBar_ul2
  );

  // Create a useState() to track the CSS class to be passed to the navbar menu
  //   unordered list element. The list will always show when the screen width
  //   is sufficiently large. When it is small, the list will be hidden by
  //   default and shown when the hamburger button is clicked.
  const [navMenuClass, setNavMenuClass] = useState(classes.navigationBar_ul1);

  // Create a useState() to toggle whether the category dropdown should show. Do
  //   the same for the about dropdown.
  const [categoryDropdownShow, setCategoryDropdownShow] = useState(false);
  const [aboutDropdownShow, setAboutDropdownShow] = useState(false);

  // Create a useState() to toggle whether the navbar menu unordered list
  //   element should show.
  const [navMenuShow, setNavMenuShow] = useState(false);

  // Define functions for changing the dropdown toggle values. Whenever the
  //   function is called, the current value of the toggle will be changed to
  //   its negation. These will be called on certain clicks.
  const categoryDropdownChangeHandler = () => {
    setCategoryDropdownShow(!categoryDropdownShow);
  };
  const aboutDropdownChangeHandler = () => {
    setAboutDropdownShow(!aboutDropdownShow);
  };

  // Define a function for changing the navbar menu toggle value. Whenever the
  //   function is called, the current value of the toggle will be changed to
  //   its negation. This will be called on certain clicks.
  const navMenuChangeHandler = () => {
    setNavMenuShow(!navMenuShow);
  };

  // Define functions for forcing the dropdown toggle values to false.
  const categoryDropdownHideHandler = () => {
    setCategoryDropdownShow(false);
  };
  const aboutDropdownHideHandler = () => {
    setAboutDropdownShow(false);
  };

  // Define a function for forcing the navbar menu toggle value to false.
  const navMenuHideHandler = () => {
    setNavMenuShow(false);
  };

  // Create useEffect() instances to update update the dropdown class if the
  //   value of the dropdown toggle changes.
  useEffect(() => {
    if (categoryDropdownShow) {
      setCategoryDropdownClass(classes.navigationBar_ul2_show);
    } else {
      setCategoryDropdownClass(classes.navigationBar_ul2);
    }
  }, [categoryDropdownShow]);
  useEffect(() => {
    if (aboutDropdownShow) {
      setAboutDropdownClass(classes.navigationBar_ul2_show);
    } else {
      setAboutDropdownClass(classes.navigationBar_ul2);
    }
  }, [aboutDropdownShow]);

  // Create a useEffect() instance to update the navbar menu class if the value
  //   of the navbar menu toggle changes.
  useEffect(() => {
    if (navMenuShow) {
      setNavMenuClass(classes.navigationBar_ul1_show);
    } else {
      setNavMenuClass(classes.navigationBar_ul1);
    }
  }, [navMenuShow]);

  return (
    <div className={classes.navigationBar_container}>
      <header>
        <div className={classes.navigationBar_titleContainer}>
          <div className={classes.navigationBar_titleNameDiv}>
            <Link to="all-questions" className={classes.navigationBar_link}>
              <img
                className={classes.navigationBar_titleNameImg}
                src={siteName}
                alt={""}
              />
            </Link>
          </div>
          <div className={classes.navigationBar_titleLogoDiv}>
            <img
              className={classes.navigationBar_titleLogoImg}
              src={siteLogo}
              alt={""}
            />
          </div>
        </div>
        <nav>
          <div className={classes.navigationBar_hamburgerButtonDiv}>
            <div
              className={classes.navigationBar_hamburgerButton}
              onClick={() => {
                navMenuChangeHandler();
                categoryDropdownHideHandler();
                aboutDropdownHideHandler();
              }}
            >
              <img
                className={classes.navigationBar_hamburgerButtonImg}
                src={navMenuShow ? hamburgerClose : hamburgerOpen}
                alt={""}
              />
            </div>
          </div>
          <ul className={navMenuClass}>
            <li className={classes.navigationBar_li1}>
              <Link to="all-questions" className={classes.navigationBar_link}>
                <div
                  className={classes.navigationBar_div1}
                  onClick={() => {
                    categoryDropdownHideHandler();
                    aboutDropdownHideHandler();
                    navMenuHideHandler();
                  }}
                >
                  All questions
                </div>
              </Link>
            </li>
            <li className={classes.navigationBar_li1}>
              <div
                className={classes.navigationBar_div1}
                onClick={() => {
                  aboutDropdownHideHandler();
                  categoryDropdownChangeHandler();
                }}
              >
                <div className={classes.navigationBar_collapseChevronDiv}>
                  <img
                    className={
                      categoryDropdownShow
                        ? classes.navigationBar_collapseChevronImgExpanded
                        : classes.navigationBar_collapseChevronImgCollapsed
                    }
                    src={dropdownChevron}
                    alt={""}
                  />
                </div>
                Categories
              </div>
              <ul className={categoryDropdownClass}>
                {categoryName.categoriesTechnical.map((nameTech) => (
                  <li key={nameTech} className={classes.navigationBar_li2}>
                    <Link
                      to={"category/" + nameTech}
                      className={classes.navigationBar_link}
                    >
                      <div
                        className={classes.navigationBar_div2}
                        onClick={() => {
                          categoryDropdownHideHandler();
                          navMenuHideHandler();
                        }}
                      >
                        {
                          categoryName.categoriesMapping[nameTech]
                            .category_display
                        }
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            <li className={classes.navigationBar_li1}>
              <Link to="random-question" className={classes.navigationBar_link}>
                <div
                  className={classes.navigationBar_div1}
                  onClick={() => {
                    categoryDropdownHideHandler();
                    aboutDropdownHideHandler();
                    navMenuHideHandler();
                  }}
                >
                  Random question
                </div>
              </Link>
            </li>
            <li className={classes.navigationBar_li1}>
              <div
                className={classes.navigationBar_div1}
                onClick={() => {
                  categoryDropdownHideHandler();
                  aboutDropdownChangeHandler();
                }}
              >
                <div className={classes.navigationBar_collapseChevronDiv}>
                  <img
                    className={
                      aboutDropdownShow
                        ? classes.navigationBar_collapseChevronImgExpanded
                        : classes.navigationBar_collapseChevronImgCollapsed
                    }
                    src={dropdownChevron}
                    alt={""}
                  />
                </div>
                More
              </div>
              <ul className={aboutDropdownClass}>
                <li className={classes.navigationBar_li2}>
                  <Link
                    to="about-polldock"
                    className={classes.navigationBar_link}
                  >
                    <div
                      className={classes.navigationBar_div2}
                      onClick={() => {
                        aboutDropdownHideHandler();
                        navMenuHideHandler();
                      }}
                    >
                      About Polldock
                    </div>
                  </Link>
                </li>
                <li className={classes.navigationBar_li2}>
                  <Link
                    to="terms-and-conditions"
                    className={classes.navigationBar_link}
                  >
                    <div
                      className={classes.navigationBar_div2}
                      onClick={() => {
                        aboutDropdownHideHandler();
                        navMenuHideHandler();
                      }}
                    >
                      Terms and Conditions
                    </div>
                  </Link>
                </li>
                <li className={classes.navigationBar_li2}>
                  <Link
                    to="privacy-policy"
                    className={classes.navigationBar_link}
                  >
                    <div
                      className={classes.navigationBar_div2}
                      onClick={() => {
                        aboutDropdownHideHandler();
                        navMenuHideHandler();
                      }}
                    >
                      Privacy Policy
                    </div>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
        <Outlet />
      </header>
      {categoryDropdownShow || aboutDropdownShow ? (
        <div
          className={classes.navigationBar_dropdownBackground}
          onClick={() => {
            categoryDropdownHideHandler();
            aboutDropdownHideHandler();
          }}
        ></div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default NavigationBar;
