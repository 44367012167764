import { useState, useEffect, useContext } from "react";
import CategoryNameContext from "../store/category-name-context";
import FilterSortContext from "../store/filter-sort-context";
import FilterSort from "../components/FilterSort";
import QuestionItem from "./QuestionItem";
import * as dfjs from "dataframe-js";

import classes from "./QuestionList.module.css";

function QuestionList(props) {
  //
  // Define a useState() to track an array of questions to be displayed to the
  //   user.
  const [questionsDisplay, setQuestionsDisplay] = useState([]);

  // Get context values for question categories and filtering/sorting.
  const categoryName = useContext(CategoryNameContext);
  const filterSortContext = useContext(FilterSortContext);

  // Define a variable to track the category selected. If the user selectes
  //   "All", set this variable to "All". Otherwise, get the display name of the
  //   category based on the technical name found in the corresponding link's
  //   URL.
  let categorySelected;
  if (props.category === "All") {
    categorySelected = "All";
  } else {
    categorySelected =
      categoryName.categoriesMapping[props.category].category_display;
  }

  // Define a function that subsets all questions based on the category link
  //   clicked by the user.
  function categorySubsetQuestionListHandler(categoryChoice, questionList) {
    if (categoryChoice === "All") {
      return questionList;
    } else {
      return questionList.filter(
        (row) => row.get("category") === categoryChoice
      );
    }
  }

  // Define a function that filters the dataframe of questions by the user
  //   filter selection: questions he has answered, questions he has not, or
  //   all.
  function filterQuestionListHandler(filterChoice, questionList) {
    if (filterChoice === "not answered") {
      return questionList.filter((row) => row.get("answered") === 0);
    } else if (filterChoice === "answered") {
      return questionList.filter((row) => row.get("answered") === 1);
    } else if (filterChoice === "all") {
      return questionList;
    }
  }

  // Define a function that sorts the filtered dataframe of questions based on
  //   the user's chosen sorting method: newest first, oldest first, or most
  //   popular.
  function sortQuestionListHandler(sortChoice, questionList) {
    if (sortChoice === "new") {
      return questionList.sortBy(["created"], true);
    } else if (sortChoice === "old") {
      return questionList.sortBy(["created"], false);
    } else if (sortChoice === "most answered") {
      return questionList.sortBy(["responses"], true);
    } else if (sortChoice === "trending") {
      return questionList.sortBy(["recent_responses", "created"], true);
    }
  }

  // Initiate a useEffect() for filtering and sorting the question data.
  useEffect(() => {
    //
    // Set constants for the chosen filter and sort selections.
    const filter = filterSortContext.filter;
    const sort = filterSortContext.sort;

    // Define a dataframe of all questions.
    const qAll = new dfjs.DataFrame(props.questions);

    // Apply category subsetting, filtering, and sorting to qAll based on the
    //   selections made by the user.
    const qFilteredSortedDf = sortQuestionListHandler(
      sort,
      filterQuestionListHandler(
        filter,
        categorySubsetQuestionListHandler(categorySelected, qAll)
      )
    );

    // Select only "id" and "question_text" columns and send the resulting
    //   dataframe to a JSON object (i.e. "collection"). Set questionsDisplay to
    //   the result.
    setQuestionsDisplay(
      qFilteredSortedDf.select("id", "question_text", "answered").toCollection()
    );
  }, [filterSortContext, props.questions, categorySelected]);

  // Return components.
  return (
    <div>
      <h1 className={classes.questionList_h1}>
        {categorySelected === "All" ? "All questions" : categorySelected}
      </h1>
      <div className={classes.questionList_underline}></div>
      <div>
        <FilterSort />
      </div>
      <div>
        {questionsDisplay.length === 0 ? (
          <div className={classes.questionList_emptyListNoteDiv}>
            There are no questions that satisfy your request. Please select a
            different category or filter option.
          </div>
        ) : (
          <ul className={classes.questionList_ul}>
            {questionsDisplay.map((question) => (
              <QuestionItem
                key={question.id}
                id={question.id}
                question_text={question.question_text}
                answered={question.answered}
              />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default QuestionList;
