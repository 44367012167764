import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function RandomQuestionResultsTransition(props) {
  //
  // Create a useNavigate instance.
  const navigate = useNavigate();

  // Store the question ID value in a constant.
  const q = props.q;

  // Navigate to the question results page for the appropriate question.
  useEffect(() => {
    navigate("/answer-question/" + q + "/");
  }, [navigate, q]);

  // Return nothing.
  return <div></div>;
}

export default RandomQuestionResultsTransition;
