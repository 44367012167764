import classes from "./OptionItem.module.css";

function OptionItem(props) {
  //
  // Define a constant to determine which style class to apply to the option
  //   item. The style will be based on whether or not the option is currently
  //   selected (i.e. has been clicked).
  const optionItemClass = props.selected
    ? classes.optionItem_divSelected
    : classes.optionItem_div;

  return (
    <li className={classes.optionItem_li}>
      <div
        className={optionItemClass}
        onClick={() => props.optionClickHandler(props.optionId)}
      >
        {props.optionText}
      </div>
    </li>
  );
}

export default OptionItem;
