import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ErrorGeneral from "./components/ErrorGeneral";

function App() {
  //
  return (
    <Routes>
      <Route path="/home/*" element={<HomePage />} />
      <Route path="/error/" element={<ErrorGeneral />} />
      <Route path="*" element={<HomePage />} />
    </Routes>
  );
}

export default App;
