import { useContext } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import * as dfjs from "dataframe-js";
import DimensionValuesContext from "../../store/dimension-values-context";
import OptionsLegend from "./OptionsLegend";

import classes from "./ResultsByDimension.module.css";

function ResultsByDimension(props) {
  //
  // Define an object of parameters taken from the visualization parameters
  //   context.
  const visualizationParameters = useContext(DimensionValuesContext);

  // Store props.resultsDisplay in a variable.
  let resultsDisplayAggPrelim = props.resultsDisplay;

  // Rename the dimension column (either "sex", "decade", or "location") to
  //   "dimension".
  resultsDisplayAggPrelim = resultsDisplayAggPrelim.rename(
    props.dimension,
    "dimension"
  );

  // Get only the desired columns for aggregating.
  resultsDisplayAggPrelim = resultsDisplayAggPrelim.select(
    "option_id",
    "option_text",
    "dimension",
    "response_count"
  );

  // Get a dataframe of all options.
  let optionsPrelim = new dfjs.DataFrame(props.options);

  // Get the option_id and option_text values for the first row in
  //   optionsPrelim. These will be used as dummy placeholders below. The values
  //   are arbitrary. It is only important that they are a combination of id and
  //   text that exist for one of the options for this question.
  const option_id_1 = optionsPrelim.toCollection()[0].option_id;
  const option_text_1 = optionsPrelim.toCollection()[0].option_text;

  // Declare a dataframe to include a dummy row for every dimension value.
  let dimensionsPrelim;

  // Define a dataframe to include a dummy row for every dimension value.
  if (props.dimension === "sex") {
    dimensionsPrelim = new dfjs.DataFrame({
      dimension: visualizationParameters.sexesAll,
    });
  } else if (props.dimension === "decade") {
    dimensionsPrelim = new dfjs.DataFrame({
      dimension: visualizationParameters.decadesAll,
    });
  } else if (props.dimension === "location") {
    dimensionsPrelim = new dfjs.DataFrame({
      dimension: visualizationParameters.locationsAll.locationsLong,
    });
  }

  // Get the dimension for the first row in dimensionsPrelim. This will be used
  //   as a dummy placeholder below. The value is arbitrary.
  const dimension_1 = dimensionsPrelim.toCollection()[0].dimension;

  // Define option_id and option_texts columns in dimensionsPrelim, inserting
  //   option_id_1 and option_text_1 into all rows.
  dimensionsPrelim = dimensionsPrelim.withColumn(
    "option_id",
    () => option_id_1
  );
  dimensionsPrelim = dimensionsPrelim.withColumn(
    "option_text",
    () => option_text_1
  );

  // Define a response_count column populated with all 0s.
  dimensionsPrelim = dimensionsPrelim.withColumn("response_count", () => 0);

  // Order the columns of dimensionsPrelim as desired.
  dimensionsPrelim = dimensionsPrelim.select(
    "option_id",
    "option_text",
    "dimension",
    "response_count"
  );

  // Get desired columns for optionsPrelim, option_id and option_text.
  optionsPrelim = optionsPrelim.select("option_id", "option_text");

  // Define a response_count column populated with all 0s.
  optionsPrelim = optionsPrelim.withColumn("response_count", () => 0);

  // Get count of distinct options (which is equal to the number of rows of
  //   optionsPrelim).
  const optionsCount = optionsPrelim.dim()[0];

  // Define a dimension column in optionsPrelim populated with dimension_1.
  optionsPrelim = optionsPrelim.withColumn("dimension", () => dimension_1);

  // Order the columns of optionsPrelim as desired.
  optionsPrelim = optionsPrelim.select(
    "option_id",
    "option_text",
    "dimension",
    "response_count"
  );

  // Append dimensionsPrelim onto resultsDisplayAggPrelim.
  resultsDisplayAggPrelim = resultsDisplayAggPrelim.union(dimensionsPrelim);

  // Append optionsPrelim onto resultsDisplayAggPrelim.
  resultsDisplayAggPrelim = resultsDisplayAggPrelim.union(optionsPrelim);

  // Aggregate response_count in resultsDisplay, grouping by option_id,
  //   option_text, and dimension.
  resultsDisplayAggPrelim = resultsDisplayAggPrelim
    .groupBy("option_id", "option_text", "dimension")
    .aggregate((group) => group.stat.sum("response_count"))
    .rename("aggregation", "response_count");

  // Sort resultsDisplayAgg by dimension, option_id.
  resultsDisplayAggPrelim = resultsDisplayAggPrelim.sortBy([
    "dimension",
    "option_id",
  ]);

  // Get only the desired columns for the data to be displayed.
  resultsDisplayAggPrelim = resultsDisplayAggPrelim.select(
    "dimension",
    "option_text",
    "response_count"
  );

  // Pivot resultsDisplayAggPrelim to get a column for each option_text value.
  //   Such columns should contain the response_count for each option. dimension
  //   values will remain as the rows. This step is necessary for the Recharts
  //   stacked bar chart to work properly.
  resultsDisplayAggPrelim = resultsDisplayAggPrelim
    .groupBy("dimension")
    .pivot("option_text", (values) => values.stat.sum("response_count"));

  // The pivoting step will leave unpopulated row-column combinations as
  //   undefined. The implication is that no responses have been submitted for
  //   that option-dimension combination. Set all such cases to 0 to ensure they
  //   display properly on the chart.
  resultsDisplayAggPrelim = resultsDisplayAggPrelim.replace(undefined, 0);

  // If "location" is the dimension of focus, replace the dimension column
  //   (location long names) with location abbreviations. The purpose is to
  //   allow for a smaller left margin to be used in the chart.
  if (props.dimension === "location") {
    resultsDisplayAggPrelim = resultsDisplayAggPrelim.withColumn(
      "dimension",
      (row) =>
        visualizationParameters.locationsAll.locationsLongToShort[
          row.get("dimension")
        ]
    );
  }

  // Convert to an array object.
  let resultsDisplayAgg = resultsDisplayAggPrelim.toCollection();

  // Convert all response count values in each row to the decimal distribution
  //   of responses for that row.
  resultsDisplayAgg = resultsDisplayAgg.map((obj) => {
    //
    // Define a new object to be constructed and to replace the existing object.
    let newObj = {};

    // Set the new object's dimension value equal to the existing object's.
    newObj.dimension = obj.dimension;

    // Get the sum of response counts over all options.
    let responseCountObj = 0;
    for (const i in Array.from(Array(optionsCount).keys())) {
      responseCountObj = responseCountObj + obj[props.options[i].option_text];
    }

    // Calculate the decimal distribution of responses.
    for (const i in Array.from(Array(optionsCount).keys())) {
      if (responseCountObj === 0) {
      } else {
        if (obj[props.options[i].option_text] === 0) {
          newObj[props.options[i].option_text] = "";
        } else {
          newObj[props.options[i].option_text] =
            obj[props.options[i].option_text] / responseCountObj;
        }
      }
    }

    // Return the newly-constructed replacement object.
    return newObj;
  });

  // If the dimension is "decade", ensure that the values for the y-axis will be
  //   displayed in the desired order. We will likely have our earliest decade
  //   option be something like "pre-1930s". We want this to show first (or last
  //   if we choose to order descending). By default, it will come ordered after
  //   all of the decade values that begin with a numeric character.
  if (props.dimension === "decade") {
    //
    // For any element in resultsDisplayAgg with dimension value that starts
    //   with "pre-", replace "pre-" with "<". Then replace the "s" at the end
    //   of that element with "". The value starting with "pre-" represents the
    //   oldest decade option, such as "pre-1930s". For aesthetics and
    //   consistency with the chart visualizations, we would want to conver this
    //   to "<1930".
    resultsDisplayAgg = resultsDisplayAgg.map(function (e) {
      e.dimension = e.dimension.replace("pre-", "<");
      if (e.dimension[0] === "<") {
        e.dimension = e.dimension.replace("s", "");
      }
      return e;
    });

    // Sort resultsDisplayAgg so that the element with dimension value starting
    //   with "<" comes first.
    resultsDisplayAgg = resultsDisplayAgg.sort(function (a, b) {
      if (a.dimension[0] === "<" && b.dimension[0] !== "<") {
        return -1;
      } else if (a.dimension[0] !== "<" && b.dimension[0] === "<") {
        return 1;
      } else {
        return 0;
      }
    });
  }

  // Define a function to generate a customized bar label.
  const renderCustomizedLabel = (props) => {
    //
    // Get the props from the LabelList component.
    const { x, y, width, height, value, barIndex } = props;

    // Determine whether the color of the label text should be black or white
    //   depending on the color of the bar.
    let textColor = "";
    if (
      visualizationParameters.lightColors.includes(
        visualizationParameters.colors[barIndex]
      )
    ) {
      textColor = "#000000";
    } else {
      textColor = "#ffffff";
    }

    // Return an SVG element containing the label value. If value is blank or
    //   null, return nothing.
    if ((value === "") | !value) {
      return;
    } else {
      //
      // If the value is less than 0.1 (i.e. 10%), return nothing. This is to
      //   ensure that we are not attempting to squeeze a label into a small bar
      //   in which the label will not fit.
      if (value < 0.1) {
        return;
      } else {
        return (
          <g>
            <text
              x={x + width / 2}
              y={y + height / 2 + 1}
              fill={textColor}
              textAnchor="middle"
              dominantBaseline="middle"
            >
              {(100 * value).toFixed(0) + "%"}
            </text>
          </g>
        );
      }
    }
  };

  // Return components.
  return (
    <div className={classes.resultsByDimension_container}>
      <OptionsLegend
        options={props.options}
        optionsCount={optionsCount}
        colors={visualizationParameters.colors}
      />
      <ResponsiveContainer
        width={"100%"}
        height={30 * dimensionsPrelim.dim()[0] + 50}
      >
        <BarChart
          data={resultsDisplayAgg}
          layout="vertical"
          margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} />
          <XAxis
            type="number"
            tickFormatter={(value) => (100 * value).toFixed(0) + "%"}
          />
          <YAxis
            type="category"
            dataKey="dimension"
            width={60}
            tickLine={false}
          />
          {Array.from(Array(optionsCount).keys()).map((j) => (
            <Bar
              key={"bar-" + j}
              dataKey={props.options[j].option_text}
              stackId="a"
              barSize={20}
              fill={visualizationParameters.colors[j]}
            >
              <LabelList
                dataKey={props.options[j].option_text}
                content={renderCustomizedLabel}
                position="center"
                barIndex={j}
              />
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ResultsByDimension;
