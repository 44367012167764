import TermsAndConditions from "../components/TermsAndConditions";

import classes from "./TermsAndConditionsPage.module.css"

function TermsAndConditionsPage() {
  //
  return (
    <div>
      <h1 className={classes.termsAndConditionsPage_h1}>Terms and Conditions</h1>
      <div className={classes.termsAndConditionsPage_underline}></div>
      <TermsAndConditions />
    </div>
  );
}

export default TermsAndConditionsPage;
