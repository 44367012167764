import { createContext, useState } from "react";

const NewUserResponseContext = createContext({
  question: NaN,
  option: NaN,
  updateQuestionSelection: (questionSelection) => {},
  updateOptionSelection: (optionSelection) => {},
});

export function NewUserResponseContextProvider(props) {
  const [questionSelected, setQuestionSelected] = useState(NaN);
  const [optionSelected, setOptionSelected] = useState(NaN);

  function updateQuestionSelectionHandler(questionSelection) {
    setQuestionSelected(questionSelection);
  }

  function updateOptionSelectionHandler(optionSelection) {
    setOptionSelected(optionSelection);
  }

  const context = {
    question: questionSelected,
    option: optionSelected,
    updateQuestionSelection: updateQuestionSelectionHandler,
    updateOptionSelection: updateOptionSelectionHandler,
  };

  return (
    <NewUserResponseContext.Provider value={context}>
      {props.children}
    </NewUserResponseContext.Provider>
  );
}

export default NewUserResponseContext;
