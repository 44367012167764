import classes from "./PrivacyPolicy.module.css";

function PrivacyPolicy() {
  //
  return (
    <div>
      <div className={classes.privacyPolicy_textBlock}>
        Last updated: February 9, 2024
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        We use Your Personal data to provide and improve the Service. By using
        the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy.
      </div>
      <h2 className={classes.privacyPolicy_h2}>
        Interpretation and Definitions
      </h2>
      <h3 className={classes.privacyPolicy_h3}>Interpretation</h3>
      <div className={classes.privacyPolicy_textBlock}>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </div>
      <h3 className={classes.privacyPolicy_h3}>Definitions</h3>
      <div className={classes.privacyPolicy_textBlock}>
        For the purposes of this Privacy Policy:
      </div>
      <ul className={classes.privacyPolicy_ul1}>
        <li className={classes.privacyPolicy_li1}>
          <strong>Account</strong> means a unique account created for You to
          access our Service or parts of our Service.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>Affiliate</strong> means an entity that controls, is
          controlled by or is under common control with a party, where "control"
          means ownership of 50% or more of the shares, equity interest or other
          securities entitled to vote for election of directors or other
          managing authority.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>Application</strong> refers to Polldock, the software program
          provided by the Company.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>Business</strong>, for the purpose of CCPA/CPRA, refers to the
          Company as the legal entity that collects Consumers' personal
          information and determines the purposes and means of the processing of
          Consumers' personal information, or on behalf of which such
          information is collected and that alone, or jointly with others,
          determines the purposes and means of the processing of consumers'
          personal information, that does business in the State of California.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>CCPA</strong> and/or <strong>CPRA</strong> refers to
          California Consumer Privacy Act (the "CCPA") as amended by the
          California Privacy Rights Act of 2020 (the "CPRA").
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>Company</strong> (referred to as either "the Company", "We",
          "Us" or "Our" in this Agreement) refers to Polldock.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>Consumer</strong>, for the purpose of the CCPA/CPRA, means a
          natural person who is a California resident. A resident, as defined in
          the law, includes (1) every individual who is in the USA for other
          than a temporary or transitory purpose, and (2) every individual who
          is domiciled in the USA who is outside the USA for a temporary or
          transitory purpose.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>Cookies</strong> are small files that are placed on Your
          computer, mobile device or any other device by a website, containing
          the details of Your browsing history on that website among its many
          uses.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>Country</strong> refers to: Indiana, United States.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>Device</strong> means any device that can access the Service
          such as a computer, a cellphone or a digital tablet.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>Do Not Track</strong> (DNT) is a concept that has been
          promoted by US regulatory authorities, in particular the U.S. Federal
          Trade Commission (FTC), for the Internet industry to develop and
          implement a mechanism for allowing internet users to control the
          tracking of their online activities across websites.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>Personal Data</strong> is any information that relates to an
          identified or identifiable individual. For the purposes of the
          CCPA/CPRA, Personal Data means any information that identifies,
          relates to, describes or is capable of being associated with, or could
          reasonably be linked, directly or indirectly, with You.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>Service</strong> refers to the Application or the Website or
          both.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>Service Provider</strong> means any natural or legal person
          who processes the data on behalf of the Company. It refers to
          third-party companies or individuals employed by the Company to
          facilitate the Service, to provide the Service on behalf of the
          Company, to perform services related to the Service or to assist the
          Company in analyzing how the Service is used.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>Usage Data</strong> refers to data collected automatically,
          either generated by the use of the Service or from the Service
          infrastructure itself (for example, the duration of a page visit).
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>Website</strong> refers to Polldock, accessible from
          https://polldock.com
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>You</strong> means the individual accessing or using the
          Service, or the company, or other legal entity on behalf of which such
          individual is accessing or using the Service, as applicable.
        </li>
      </ul>
      <h2 className={classes.privacyPolicy_h2}>
        Collecting and Using Your Personal Data
      </h2>
      <h3 className={classes.privacyPolicy_h3}>Types of Data Collected</h3>
      <h4 className={classes.privacyPolicy_h4}>Personal Data</h4>
      <div className={classes.privacyPolicy_textBlock}>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to:
      </div>
      <ul className={classes.privacyPolicy_ul1}>
        <li className={classes.privacyPolicy_li1}>Usage Data</li>
      </ul>
      <h4 className={classes.privacyPolicy_h4}>Usage Data</h4>
      <div className={classes.privacyPolicy_textBlock}>
        Usage Data is collected automatically when using the Service.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        Usage Data may include information such as Your Device's Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that You visit, the time and date of Your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of Your mobile device, Your mobile operating system, the type
        of mobile Internet browser You use, unique device identifiers and other
        diagnostic data.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        We may also collect information that Your browser sends whenever You
        visit our Service or when You access the Service by or through a mobile
        device.
      </div>
      <h4 className={classes.privacyPolicy_h4}>
        Tracking Technologies and Cookies
      </h4>
      <div className={classes.privacyPolicy_textBlock}>
        We use Cookies and similar tracking technologies to track the activity
        on Our Service and store certain information. Tracking technologies used
        are beacons, tags, and scripts to collect and track information and to
        improve and analyze Our Service. The technologies We use may include:
      </div>
      <ul className={classes.privacyPolicy_ul1}>
        <li className={classes.privacyPolicy_li1}>
          <strong>Cookies or Browser Cookies</strong>. A Cookie is a small file
          placed on Your Device. You can instruct Your browser to refuse all
          Cookies or to indicate when a Cookie is being sent. However, if You do
          not accept Cookies, You may not be able to use some parts of our
          Service. Unless You have adjusted Your browser setting so that it will
          refuse Cookies, our Service may use Cookies.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>Web Beacons</strong>. Certain sections of our Service and our
          emails may contain small electronic files known as web beacons (also
          referred to as clear gifs, pixel tags, and single-pixel gifs) that
          permit the Company, for example, to count users who have visited those
          pages or opened an email and for other related website statistics (for
          example, recording the popularity of a certain section and verifying
          system and server integrity).
        </li>
      </ul>
      <div className={classes.privacyPolicy_textBlock}>
        Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
        remain on Your personal computer or mobile device when You go offline,
        while Session Cookies are deleted as soon as You close Your web browser.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        We use both Session and Persistent Cookies for the purposes set out
        below:
      </div>
      <ul className={classes.privacyPolicy_ul1}>
        <li className={classes.privacyPolicy_li2}>
          <div className={classes.privacyPolicy_textLineDiv}>
            <strong>Necessary / Essential Cookies</strong>
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Type: Session Cookies
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Administered by: Us
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Purpose: These Cookies are essential to provide You with services
            available through the Website and to enable You to use some of its
            features. They help to authenticate users and prevent fraudulent use
            of user accounts. Without these Cookies, the services that You have
            asked for cannot be provided, and We only use these Cookies to
            provide You with those services.
          </div>
        </li>
        <li className={classes.privacyPolicy_li2}>
          <div className={classes.privacyPolicy_textLineDiv}>
            <strong>Cookies Policy / Notice Acceptance Cookies</strong>
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Type: Persistent Cookies
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Administered by: Us{" "}
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Purpose: These Cookies identify if users have accepted the use of
            Cookies on the Website.
          </div>
        </li>
        <li className={classes.privacyPolicy_li2}>
          <div className={classes.privacyPolicy_textLineDiv}>
            <strong>Functionality Cookies</strong>
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Type: Persistent Cookies
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Administered by: Us
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Purpose: These Cookies allow us to remember choices You make when
            You use the Website, such as remembering Your login details or
            language preference. The purpose of these Cookies is to provide You
            with a more personal experience and to avoid You having to re-enter
            Your preferences every time You use the Website.
          </div>
        </li>
      </ul>
      <div className={classes.privacyPolicy_textBlock}>
        For more information about the Cookies we use and Your choices regarding
        Cookies, please visit the Cookies section of our Privacy Policy.
      </div>
      <h3 className={classes.privacyPolicy_h3}>Use of Your Personal Data</h3>
      <div className={classes.privacyPolicy_textBlock}>
        The Company may use Personal Data for the following purposes:
      </div>
      <ul className={classes.privacyPolicy_ul1}>
        <li className={classes.privacyPolicy_li1}>
          <strong>To provide and maintain our Service</strong>, including to
          monitor the usage of our Service.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>To manage Your Account</strong>: to manage Your registration
          as a user of the Service. The Personal Data You provide can give You
          access to different functionalities of the Service that are available
          to You as a registered user.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>For the performance of a contract</strong>: the development,
          compliance and undertaking of the purchase contract for the products,
          items or services You have purchased or of any other contract with Us
          through the Service.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>To contact You</strong>: To contact You by email, telephone
          calls, SMS, or other equivalent forms of electronic communication,
          such as a mobile application's push notifications regarding updates or
          informative communications related to the functionalities, products or
          contracted services, including the security updates, when necessary or
          reasonable for their implementation.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>To provide You</strong> with news, special offers and general
          information about other goods, services and events which we offer that
          are similar to those that You have already purchased or enquired about
          unless You have opted not to receive such information.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>To manage Your requests</strong>: To attend and manage Your
          requests to Us.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>For business transfers</strong>: We may use Your information
          to evaluate or conduct a merger, divestiture, restructuring,
          reorganization, dissolution, or other sale or transfer of some or all
          of Our assets, whether as a going concern or as part of bankruptcy,
          liquidation, or similar proceeding, in which Personal Data held by Us
          about our Service users is among the assets transferred.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>For other purposes</strong>: We may use Your information for
          other purposes, such as data analysis, identifying usage trends,
          determining the effectiveness of our promotional campaigns and to
          evaluate and improve our Service, products, services, marketing and
          Your experience.
        </li>
      </ul>
      <div className={classes.privacyPolicy_textBlock}>
        We may share Your personal information in the following situations:
      </div>
      <ul className={classes.privacyPolicy_ul1}>
        <li className={classes.privacyPolicy_li1}>
          <strong>With Service Providers</strong>: We may share Your personal
          information with Service Providers to monitor and analyze the use of
          our Service, to contact You.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>For business transfers</strong>: We may share or transfer Your
          personal information in connection with, or during negotiations of,
          any merger, sale of Company assets, financing, or acquisition of all
          or a portion of Our business to another company.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>With Affiliates</strong>: We may share Your information with
          Our affiliates, in which case we will require those affiliates to
          honor this Privacy Policy. Affiliates include Our parent company and
          any other subsidiaries, joint venture partners or other companies that
          We control or that are under common control with Us.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>With business partners</strong>: We may share Your information
          with Our business partners to offer You certain products, services or
          promotions.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>With other users</strong>: when You share personal information
          or otherwise interact in the public areas with other users, such
          information may be viewed by all users and may be publicly distributed
          outside.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>With Your consent</strong>: We may disclose Your personal
          information for any other purpose with Your consent.
        </li>
      </ul>
      <h3 className={classes.privacyPolicy_h3}>
        Retention of Your Personal Data
      </h3>
      <div className={classes.privacyPolicy_textBlock}>
        The Company will retain Your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use Your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain Your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        The Company will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the
        functionality of Our Service, or We are legally obligated to retain this
        data for longer time periods.
      </div>
      <h3 className={classes.privacyPolicy_h3}>
        Transfer of Your Personal Data
      </h3>
      <div className={classes.privacyPolicy_textBlock}>
        Your information, including Personal Data, is processed at the Company's
        operating offices and in any other places where the parties involved in
        the processing are located. It means that this information may be
        transferred to — and maintained on — computers located outside of Your
        state, province, country or other governmental jurisdiction where the
        data protection laws may differ than those from Your jurisdiction.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        Your consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        The Company will take all steps reasonably necessary to ensure that Your
        data is treated securely and in accordance with this Privacy Policy and
        no transfer of Your Personal Data will take place to an organization or
        a country unless there are adequate controls in place including the
        security of Your data and other personal information.
      </div>
      <h3 className={classes.privacyPolicy_h3}>Delete Your Personal Data</h3>
      <div className={classes.privacyPolicy_textBlock}>
        You have the right to delete or request that We assist in deleting the
        Personal Data that We have collected about You.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        Our Service may give You the ability to delete certain information about
        You from within the Service.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        You may update, amend, or delete Your information at any time by signing
        in to Your Account, if You have one, and visiting the account settings
        section that allows You to manage Your personal information. You may
        also contact Us to request access to, correct, or delete any personal
        information that You have provided to Us.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        Please note, however, that We may need to retain certain information
        when we have a legal obligation or lawful basis to do so.
      </div>
      <h3 className={classes.privacyPolicy_h3}>
        Disclosure of Your Personal Data
      </h3>
      <h4 className={classes.privacyPolicy_h4}>Business Transactions</h4>
      <div className={classes.privacyPolicy_textBlock}>
        If the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy.
      </div>
      <h4 className={classes.privacyPolicy_h4}>Law enforcement</h4>
      <div className={classes.privacyPolicy_textBlock}>
        Under certain circumstances, the Company may be required to disclose
        Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).
      </div>
      <h4 className={classes.privacyPolicy_h4}>Other legal requirements</h4>
      <div className={classes.privacyPolicy_textBlock}>
        The Company may disclose Your Personal Data in the good faith belief
        that such action is necessary to:
      </div>
      <ul className={classes.privacyPolicy_ul1}>
        <li className={classes.privacyPolicy_li1}>
          Comply with a legal obligation
        </li>
        <li className={classes.privacyPolicy_li1}>
          Protect and defend the rights or property of the Company
        </li>
        <li className={classes.privacyPolicy_li1}>
          Prevent or investigate possible wrongdoing in connection with the
          Service
        </li>
        <li className={classes.privacyPolicy_li1}>
          Protect the personal safety of Users of the Service or the public
        </li>
        <li className={classes.privacyPolicy_li1}>
          Protect against legal liability
        </li>
      </ul>
      <h3 className={classes.privacyPolicy_h3}>
        Security of Your Personal Data
      </h3>
      <div className={classes.privacyPolicy_textBlock}>
        The security of Your Personal Data is important to Us, but remember that
        no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable
        means to protect Your Personal Data, We cannot guarantee its absolute
        security.
      </div>
      <h2 className={classes.privacyPolicy_h2}>
        Detailed Information on the Processing of Your Personal Data
      </h2>
      <div className={classes.privacyPolicy_textBlock}>
        The Service Providers We use may have access to Your Personal Data.
        These third-party vendors collect, store, use, process and transfer
        information about Your activity on Our Service in accordance with their
        Privacy Policies.
      </div>
      <h3 className={classes.privacyPolicy_h3}>
        Usage, Performance and Miscellaneous
      </h3>
      <div className={classes.privacyPolicy_textBlock}>
        We may use third-party Service Providers to provide better improvement
        of our Service.
      </div>
      <ul className={classes.privacyPolicy_ul1}>
        <li className={classes.privacyPolicy_li1}>
          <strong>Turnstile by Cloudflare</strong>. Their Privacy Policy can be
          viewed at{" "}
          <a
            className={classes.privacyPolicy_link}
            href="https://www.cloudflare.com/privacypolicy/"
          >
            https://www.cloudflare.com/privacypolicy/
          </a>
          .
        </li>
      </ul>
      <h3 className={classes.privacyPolicy_h3}>Analytics</h3>
      <div className={classes.privacyPolicy_textBlock}>
        We may use third-party Service providers to monitor and analyze the use
        of our Service.
      </div>
      <ul className={classes.privacyPolicy_ul1}>
        <li className={classes.privacyPolicy_li1}>
          <strong>Google Analytics</strong>. Google Analytics is a web analytics
          service offered by Google that tracks and reports website traffic.
          Google uses the data collected to track and monitor the use of our
          Service. This data is shared with other Google services. Google may
          use the collected data to contextualise and personalise the ads of its
          own advertising network. You can opt-out of having made your activity
          on the Service available to Google Analytics by installing the Google
          Analytics opt-out browser add-on. The add-on prevents the Google
          Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing
          information with Google Analytics about visits activity. For more
          information on the privacy practices of Google, please visit the
          Google Privacy & Terms web page:{" "}
          <a
            className={classes.privacyPolicy_link}
            href="https://policies.google.com/privacy"
          >
            https://policies.google.com/privacy
          </a>
          .
        </li>
      </ul>
      <h2 className={classes.privacyPolicy_h2}>CCPA/CPRA Privacy Notice</h2>
      <div className={classes.privacyPolicy_textBlock}>
        This privacy notice section for California residents supplements the
        information contained in Our Privacy Policy and it applies solely to all
        visitors, users, and others who reside in the State of California.
      </div>
      <h3 className={classes.privacyPolicy_h3}>
        Categories of Personal Information Collected
      </h3>
      <div className={classes.privacyPolicy_textBlock}>
        We collect information that identifies, relates to, describes,
        references, is capable of being associated with, or could reasonably be
        linked, directly or indirectly, with a particular Consumer or Device.
        The following is a list of categories of personal information which we
        may collect or may have been collected from California residents within
        the last twelve (12) months.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        Please note that the categories and examples provided in the list below
        are those defined in the CCPA/CPRA. This does not mean that all examples
        of that category of personal information were in fact collected by Us,
        but reflects our good faith belief to the best of Our knowledge that
        some of that information from the applicable category may be and may
        have been collected. For example, certain categories of personal
        information would only be collected if You provided such personal
        information directly to Us.
      </div>
      <ul className={classes.privacyPolicy_ul1}>
        <li className={classes.privacyPolicy_li2}>
          <div className={classes.privacyPolicy_textLineDiv}>
            <strong>Category A: Identifiers.</strong>
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Examples: A real name, alias, postal address, unique personal
            identifier, online identifier, Internet Protocol address, email
            address, account name, driver's license number, passport number, or
            other similar identifiers.
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Collected: No.
          </div>
        </li>
        <li className={classes.privacyPolicy_li2}>
          <div className={classes.privacyPolicy_textLineDiv}>
            <strong>
              Category B: Personal information categories listed in the
              California Customer Records statute (Cal. Civ. Code § 1798.80(e)).
            </strong>
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Examples: A name, signature, Social Security number, physical
            characteristics or description, address, telephone number, passport
            number, driver's license or state identification card number,
            insurance policy number, education, employment, employment history,
            bank account number, credit card number, debit card number, or any
            other financial information, medical information, or health
            insurance information. Some personal information included in this
            category may overlap with other categories.
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Collected: No.
          </div>
        </li>
        <li className={classes.privacyPolicy_li2}>
          <div className={classes.privacyPolicy_textLineDiv}>
            <strong>
              Category C: Protected classification characteristics under
              California or federal law.
            </strong>
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Examples: Age (40 years or older), race, color, ancestry, national
            origin, citizenship, religion or creed, marital status, medical
            condition, physical or mental disability, sex (including gender,
            gender identity, gender expression, pregnancy or childbirth and
            related medical conditions), sexual orientation, veteran or military
            status, genetic information (including familial genetic
            information).
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Collected: No.
          </div>
        </li>
        <li className={classes.privacyPolicy_li2}>
          <div className={classes.privacyPolicy_textLineDiv}>
            <strong>Category D: Commercial information.</strong>
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Examples: Records and history of products or services purchased or
            considered.
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Collected: No.
          </div>
        </li>
        <li className={classes.privacyPolicy_li2}>
          <div className={classes.privacyPolicy_textLineDiv}>
            <strong>Category E: Biometric information.</strong>
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Examples: Genetic, physiological, behavioral, and biological
            characteristics, or activity patterns used to extract a template or
            other identifier or identifying information, such as, fingerprints,
            faceprints, and voiceprints, iris or retina scans, keystroke, gait,
            or other physical patterns, and sleep, health, or exercise data.
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Collected: No.
          </div>
        </li>
        <li className={classes.privacyPolicy_li2}>
          <div className={classes.privacyPolicy_textLineDiv}>
            <strong>
              Category F: Internet or other similar network activity.
            </strong>
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Examples: Interaction with our Service or advertisement.
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Collected: Yes.
          </div>
        </li>
        <li className={classes.privacyPolicy_li2}>
          <div className={classes.privacyPolicy_textLineDiv}>
            <strong>Category G: Geolocation data.</strong>
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Examples: Approximate physical location.
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Collected: No.
          </div>
        </li>
        <li className={classes.privacyPolicy_li2}>
          <div className={classes.privacyPolicy_textLineDiv}>
            <strong>Category H: Sensory data.</strong>
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Examples: Audio, electronic, visual, thermal, olfactory, or similar
            information.
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Collected: No.
          </div>
        </li>
        <li className={classes.privacyPolicy_li2}>
          <div className={classes.privacyPolicy_textLineDiv}>
            <strong>
              Category I: Professional or employment-related information.
            </strong>
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Examples: Current or past job history or performance evaluations.
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Collected: No.
          </div>
        </li>
        <li className={classes.privacyPolicy_li2}>
          <div className={classes.privacyPolicy_textLineDiv}>
            <strong>
              Category J: Non-public education information (per the Family
              Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34
              C.F.R. Part 99)).
            </strong>
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Examples: Education records directly related to a student maintained
            by an educational institution or party acting on its behalf, such as
            grades, transcripts, class lists, student schedules, student
            identification codes, student financial information, or student
            disciplinary records.
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Collected: No.
          </div>
        </li>
        <li className={classes.privacyPolicy_li2}>
          <div className={classes.privacyPolicy_textLineDiv}>
            <strong>
              Category K: Inferences drawn from other personal information.
            </strong>
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Examples: Profile reflecting a person's preferences,
            characteristics, psychological trends, predispositions, behavior,
            attitudes, intelligence, abilities, and aptitudes.
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Collected: No.
          </div>
        </li>
        <li className={classes.privacyPolicy_li2}>
          <div className={classes.privacyPolicy_textLineDiv}>
            <strong>Category L: Sensitive personal information.</strong>
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Examples: Account login and password information, geolocation data.
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Collected: No.
          </div>
        </li>
      </ul>
      <div className={classes.privacyPolicy_textBlock}>
        Under CCPA/CPRA, personal information does not include:
      </div>
      <ul className={classes.privacyPolicy_ul1}>
        <li className={classes.privacyPolicy_li1}>
          Publicly available information from government records
        </li>
        <li className={classes.privacyPolicy_li1}>
          Deidentified or aggregated consumer information
        </li>
        <li className={classes.privacyPolicy_li1}>
          Information excluded from the CCPA/CPRA's scope, such as:
          <ul>
            <li className={classes.privacyPolicy_li1}>
              Health or medical information covered by the Health Insurance
              Portability and Accountability Act of 1996 (HIPAA) and the
              California Confidentiality of Medical Information Act (CMIA) or
              clinical trial data
            </li>
            <li className={classes.privacyPolicy_li1}>
              Personal Information covered by certain sector-specific privacy
              laws, including the Fair Credit Reporting Act (FRCA), the
              Gramm-Leach-Bliley Act (GLBA) or California Financial Information
              Privacy Act (FIPA), and the Driver's Privacy Protection Act of
              1994
            </li>
          </ul>
        </li>
      </ul>
      <h3 className={classes.privacyPolicy_h3}>
        Sources of Personal Information
      </h3>
      <div className={classes.privacyPolicy_textBlock}>
        We obtain the categories of personal information listed above from the
        following categories of sources:
      </div>
      <ul className={classes.privacyPolicy_ul1}>
        <li className={classes.privacyPolicy_li1}>
          <strong>Directly from You</strong>. For example, from the forms You
          complete on our Service, preferences You express or provide through
          our Service.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>Indirectly from You</strong>. For example, from observing Your
          activity on our Service.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>Automatically from You</strong>. For example, through Cookies
          We or our Service Providers set on Your Device as You navigate through
          our Service.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>From Service Providers</strong>. For example, or other
          third-party vendors that We use to provide the Service to You.
        </li>
      </ul>
      <h3 className={classes.privacyPolicy_h3}>Use of Personal Information</h3>
      <div className={classes.privacyPolicy_textBlock}>
        We may use or disclose personal information We collect for "business
        purposes" or "commercial purposes" (as defined under the CCPA/CPRA),
        which may include the following examples:
      </div>
      <ul className={classes.privacyPolicy_ul1}>
        <li className={classes.privacyPolicy_li1}>
          To operate our Service and provide You with Our Service.
        </li>
        <li className={classes.privacyPolicy_li1}>
          To provide You with support and to respond to Your inquiries,
          including to investigate and address Your concerns and monitor and
          improve our Service.
        </li>
        <li className={classes.privacyPolicy_li1}>
          To fulfill or meet the reason You provided the information. For
          example, if You share Your contact information to ask a question about
          our Service, We will use that personal information to respond to Your
          inquiry.
        </li>
        <li className={classes.privacyPolicy_li1}>
          To respond to law enforcement requests and as required by applicable
          law, court order, or governmental regulations.
        </li>
        <li className={classes.privacyPolicy_li1}>
          As described to You when collecting Your personal information or as
          otherwise set forth in the CCPA/CPRA.
        </li>
        <li className={classes.privacyPolicy_li1}>
          For internal administrative and auditing purposes.
        </li>
        <li className={classes.privacyPolicy_li1}>
          To detect security incidents and protect against malicious, deceptive,
          fraudulent or illegal activity, including, when necessary, to
          prosecute those responsible for such activities.
        </li>
        <li className={classes.privacyPolicy_li1}>Other one-time uses.</li>
      </ul>
      <div className={classes.privacyPolicy_textBlock}>
        Please note that the examples provided above are illustrative and not
        intended to be exhaustive. For more details on how we use this
        information, please refer to the "Use of Your Personal Data" section.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        If We decide to collect additional categories of personal information or
        use the personal information We collected for materially different,
        unrelated, or incompatible purposes We will update this Privacy Policy.
      </div>
      <h3 className={classes.privacyPolicy_h3}>
        Disclosure of Personal Information
      </h3>
      <div className={classes.privacyPolicy_textBlock}>
        We may use or disclose and may have used or disclosed in the last twelve
        (12) months the following categories of personal information for
        business or commercial purposes:
      </div>
      <ul className={classes.privacyPolicy_ul1}>
        <li className={classes.privacyPolicy_li1}>
          Internet or other similar network activity
        </li>
      </ul>
      <div className={classes.privacyPolicy_textBlock}>
        Please note that the categories listed above are those defined in the
        CCPA/CPRA. This does not mean that all examples of that category of
        personal information were in fact disclosed, but reflects our good faith
        belief to the best of our knowledge that some of that information from
        the applicable category may be and may have been disclosed.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        When We disclose personal information for a business purpose or a
        commercial purpose, We enter a contract that describes the purpose and
        requires the recipient to both keep that personal information
        confidential and not use it for any purpose except performing the
        contract.
      </div>
      <h3 className={classes.privacyPolicy_h3}>
        Share of Personal Information
      </h3>
      <div className={classes.privacyPolicy_textBlock}>
        We may share, and have shared in the last twelve (12) months, Your
        personal information identified in the above categories with the
        following categories of third parties:
      </div>
      <ul className={classes.privacyPolicy_ul1}>
        <li className={classes.privacyPolicy_li1}>Service Providers</li>
        <li className={classes.privacyPolicy_li1}>Our affiliates</li>
        <li className={classes.privacyPolicy_li1}>Our business partners</li>
        <li className={classes.privacyPolicy_li1}>
          Third party vendors to whom You or Your agents authorize Us to
          disclose Your personal information in connection with products or
          services We provide to You
        </li>
        <li className={classes.privacyPolicy_li1}></li>
      </ul>
      <h3 className={classes.privacyPolicy_h3}>Sale of Personal Information</h3>
      <div className={classes.privacyPolicy_textBlock}>
        As defined in the CCPA/CPRA, "sell" and "sale" mean selling, renting,
        releasing, disclosing, disseminating, making available, transferring, or
        otherwise communicating orally, in writing, or by electronic or other
        means, a Consumer's personal information by the Business to a third
        party for valuable consideration. This means that We may have received
        some kind of benefit in return for sharing personal information, but not
        necessarily a monetary benefit.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        We do not sell personal information as the term sell is commonly
        understood. We do allow Service Providers to use Your personal
        information for the business purposes described in Our Privacy Policy,
        and these may be deemed a sale under CCPA/CPRA.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        We may sell and may have sold in the last twelve (12) months the
        following categories of personal information:
      </div>
      <ul className={classes.privacyPolicy_ul1}>
        <li className={classes.privacyPolicy_li1}>None</li>
      </ul>
      <div className={classes.privacyPolicy_textBlock}>
        Please note that the categories listed above are those defined in the
        CCPA/CPRA. This does not mean that all examples of that category of
        personal information were in fact sold, but reflects our good faith
        belief to the best of Our knowledge that some of that information from
        the applicable category may be and may have been shared for value in
        return.
      </div>
      <h3 className={classes.privacyPolicy_h3}>
        Sale of Personal Information of Minors Under 16 Years of Age
      </h3>
      <div className={classes.privacyPolicy_textBlock}>
        We do not knowingly collect personal information from minors under the
        age of 16 through our Service, although certain third party websites
        that we link to may do so. These third-party websites have their own
        terms of use and privacy policies and We encourage parents and legal
        guardians to monitor their children's Internet usage and instruct their
        children to never provide information on other websites without their
        permission.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        We do not sell the personal information of Consumers We actually know
        are less than 16 years of age, unless We receive affirmative
        authorization (the "right to opt-in") from either the Consumer who is
        between 13 and 16 years of age, or the parent or guardian of a Consumer
        less than 13 years of age. Consumers who opt-in to the sale of personal
        information may opt-out of future sales at any time. To exercise the
        right to opt-out, You (or Your authorized representative) may submit a
        request to Us by contacting Us.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        If You have reason to believe that a child under the age of 13 (or 16)
        has provided Us with personal information, please contact Us with
        sufficient detail to enable Us to delete that information.
      </div>
      <h3 className={classes.privacyPolicy_h3}>
        Your Rights under the CCPA/CPRA
      </h3>
      <div className={classes.privacyPolicy_textBlock}>
        The CCPA/CPRA provides California residents with specific rights
        regarding their personal information. If You are a resident of
        California, You have the following rights:
      </div>
      <ul className={classes.privacyPolicy_ul1}>
        <li className={classes.privacyPolicy_li1}>
          <strong>The right to notice</strong>. You have the right to be
          notified which categories of Personal Data are being collected and the
          purposes for which the Personal Data is being used.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>The right to know/access</strong>. Under CCPA/CPRA, You have
          the right to request that We disclose information to You about Our
          collection, use, sale, disclosure for business purposes and share of
          personal information. Once We receive and confirm Your request, We
          will disclose to You:
          <ul className={classes.privacyPolicy_ul1}>
            <li className={classes.privacyPolicy_li1}>
              The categories of personal information We collected about You
            </li>
            <li className={classes.privacyPolicy_li1}>
              The categories of sources for the personal information We
              collected about You
            </li>
            <li className={classes.privacyPolicy_li1}>
              Our business or commercial purposes for collecting or selling that
              personal information
            </li>
            <li className={classes.privacyPolicy_li1}>
              The categories of third parties with whom We share that personal
              information
            </li>
            <li className={classes.privacyPolicy_li1}>
              The specific pieces of personal information We collected about You
            </li>
            <li className={classes.privacyPolicy_li1}>
              If we sold Your personal information or disclosed Your personal
              information for a business purpose, We will disclose to You:
              <ul className={classes.privacyPolicy_ul1}>
                <li className={classes.privacyPolicy_li1}>
                  The categories of personal information categories sold
                </li>
                <li className={classes.privacyPolicy_li1}>
                  The categories of personal information categories disclosed
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>
            The right to say no to the sale or sharing of Personal Data
            (opt-out)
          </strong>
          . You have the right to direct Us to not sell Your personal
          information. To submit an opt-out request, please see the "Do Not Sell
          My Personal Information" section or contact Us.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>The right to correct Personal Data</strong>. You have the
          right to correct or rectify any inaccurate personal information about
          You that We collected. Once We receive and confirm Your request, We
          will use commercially reasonable efforts to correct (and direct our
          Service Providers to correct) Your personal information, unless an
          exception applies.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>
            The right to limit use and disclosure of sensitive Personal Data
          </strong>
          . You have the right to request to limit the use or disclosure of
          certain sensitive personal information We collected about You, unless
          an exception applies. To submit, please see the "Limit the Use or
          Disclosure of My Sensitive Personal Information" section or contact
          Us.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>The right to delete Personal Data</strong>. You have the right
          to request the deletion of Your Personal Data under certain
          circumstances, subject to certain exceptions. Once We receive and
          confirm Your request, We will delete (and direct Our Service Providers
          to delete) Your personal information from our records, unless an
          exception applies. We may deny Your deletion request if retaining the
          information is necessary for Us or Our Service Providers to:
          <ul className={classes.privacyPolicy_ul1}>
            <li className={classes.privacyPolicy_li1}>
              Complete the transaction for which We collected the personal
              information, provide a good or service that You requested, take
              actions reasonably anticipated within the context of our ongoing
              business relationship with You, or otherwise perform our contract
              with You.
            </li>
            <li className={classes.privacyPolicy_li1}>
              Detect security incidents, protect against malicious, deceptive,
              fraudulent, or illegal activity, or prosecute those responsible
              for such activities.
            </li>
            <li className={classes.privacyPolicy_li1}>
              Debug products to identify and repair errors that impair existing
              intended functionality.
            </li>
            <li className={classes.privacyPolicy_li1}>
              Exercise free speech, ensure the right of another consumer to
              exercise their free speech rights, or exercise another right
              provided for by law.
            </li>
            <li className={classes.privacyPolicy_li1}>
              Comply with the California Electronic Communications Privacy Act
              (Cal. Penal Code § 1546 et. seq.).
            </li>
            <li className={classes.privacyPolicy_li1}>
              Engage in public or peer-reviewed scientific, historical, or
              statistical research in the public interest that adheres to all
              other applicable ethics and privacy laws, when the information's
              deletion may likely render impossible or seriously impair the
              research's achievement, if You previously provided informed
              consent.
            </li>
            <li className={classes.privacyPolicy_li1}>
              Enable solely internal uses that are reasonably aligned with
              consumer expectations based on Your relationship with Us.
            </li>
            <li className={classes.privacyPolicy_li1}>
              Comply with a legal obligation.
            </li>
            <li className={classes.privacyPolicy_li1}>
              Make other internal and lawful uses of that information that are
              compatible with the context in which You provided it.
            </li>
          </ul>
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>The right not to be discriminated against</strong>. You have
          the right not to be discriminated against for exercising any of Your
          consumer's rights, including by:
          <ul className={classes.privacyPolicy_ul1}>
            <li className={classes.privacyPolicy_li1}>
              Denying goods or services to You
            </li>
            <li className={classes.privacyPolicy_li1}>
              Charging different prices or rates for goods or services,
              including the use of discounts or other benefits or imposing
              penalties
            </li>
            <li className={classes.privacyPolicy_li1}>
              Providing a different level or quality of goods or services to You
            </li>
            <li className={classes.privacyPolicy_li1}>
              Suggesting that You will receive a different price or rate for
              goods or services or a different level or quality of goods or
              services
            </li>
          </ul>
        </li>
      </ul>
      <h3 className={classes.privacyPolicy_h3}>
        Exercising Your CCPA/CPRA Data Protection Rights
      </h3>
      <div className={classes.privacyPolicy_textBlock}>
        Please see the "Do Not Sell My Personal Information" section and "Limit
        the Use or Disclosure of My Sensitive Personal Information" section for
        more information on how to opt out and limit the use of sensitive
        information collected.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        Additionally, in order to exercise any of Your rights under the
        CCPA/CPRA, and if You are a California resident, You can contact Us:
      </div>
      <ul className={classes.privacyPolicy_ul1}>
        <li className={classes.privacyPolicy_li1}>
          By email: contact@polldock.com
        </li>
      </ul>
      <div className={classes.privacyPolicy_textBlock}>
        Only You, or a person registered with the California Secretary of State
        that You authorize to act on Your behalf, may make a verifiable request
        related to Your personal information.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        Your request to Us must:
      </div>
      <ul className={classes.privacyPolicy_ul1}>
        <li className={classes.privacyPolicy_li1}>
          Provide sufficient information that allows Us to reasonably verify You
          are the person about whom We collected personal information or an
          authorized representative
        </li>
        <li className={classes.privacyPolicy_li1}>
          Describe Your request with sufficient detail that allows Us to
          properly understand, evaluate, and respond to it
        </li>
      </ul>
      <div className={classes.privacyPolicy_textBlock}>
        We cannot respond to Your request or provide You with the required
        information if We cannot:
      </div>
      <ul className={classes.privacyPolicy_ul1}>
        <li className={classes.privacyPolicy_li1}>
          Verify Your identity or authority to make the request
        </li>
        <li className={classes.privacyPolicy_li1}>
          And confirm that the personal information relates to You
        </li>
      </ul>
      <div className={classes.privacyPolicy_textBlock}>
        We will disclose and deliver the required information free of charge
        within 45 days of receiving Your verifiable request. The time period to
        provide the required information may be extended once by an additional
        45 days when reasonably necessary and with prior notice.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        Any disclosures We provide will only cover the 12-month period preceding
        the verifiable request's receipt.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        For data portability requests, We will select a format to provide Your
        personal information that is readily usable and should allow You to
        transmit the information from one entity to another entity without
        hindrance.
      </div>
      <h3 className={classes.privacyPolicy_h3}>
        Do Not Sell My Personal Information
      </h3>
      <div className={classes.privacyPolicy_textBlock}>
        As defined in the CCPA/CPRA, "sell" and "sale" mean selling, renting,
        releasing, disclosing, disseminating, making available, transferring, or
        otherwise communicating orally, in writing, or by electronic or other
        means, a Consumer's personal information by the Business to a third
        party for valuable consideration. This means that We may have received
        some kind of benefit in return for sharing personal information, but not
        necessarily a monetary benefit.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        We do not sell personal information as the term sell is commonly
        understood. We do allow Service Providers to use Your personal
        information for the business purposes described in Our Privacy Policy,
        and these may be deemed a sale under CCPA/CPRA.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        You have the right to opt-out of the sale of Your personal information.
        Once We receive and confirm a verifiable consumer request from You, we
        will stop selling Your personal information. To exercise Your right to
        opt-out, please contact Us.
      </div>
      <h3 className={classes.privacyPolicy_h3}>
        Limit the Use or Disclosure of My Sensitive Personal Information
      </h3>
      <div className={classes.privacyPolicy_textBlock}>
        If You are a California resident, You have the right to limit the use
        and disclosure of Your sensitive personal information to that use which
        is necessary to perform the services or provide the goods reasonably
        expected by an average Consumer who requests such services or goods.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        We collect, use and disclose sensitive personal information in ways that
        are necessary to provide the Service. For more information on how We use
        Your personal information, please see the "Use of Your Personal Data"
        section or contact us.
      </div>
      <h2 className={classes.privacyPolicy_h2}>
        "Do Not Track" Policy as Required by California Online Privacy
        Protection Act (CalOPPA)
      </h2>
      <div className={classes.privacyPolicy_textBlock}>
        Our Service does not respond to Do Not Track signals.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        However, some third party websites do keep track of Your browsing
        activities. If You are visiting such websites, You can set Your
        preferences in Your web browser to inform websites that You do not want
        to be tracked. You can enable or disable DNT by visiting the preferences
        or settings page of Your web browser.
      </div>
      <h2 className={classes.privacyPolicy_h2}>
        Your California Privacy Rights (California's Shine the Light law)
      </h2>
      <div className={classes.privacyPolicy_textBlock}>
        Under California Civil Code Section 1798 (California's Shine the Light
        law), California residents with an established business relationship
        with us can request information once a year about sharing their Personal
        Data with third parties for the third parties' direct marketing
        purposes.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        If You'd like to request more information under the California Shine the
        Light law, and if You are a California resident, You can contact Us
        using the contact information provided below.
      </div>
      <h2 className={classes.privacyPolicy_h2}>
        California Privacy Rights for Minor Users (California Business and
        Professions Code Section 22581)
      </h2>
      <div className={classes.privacyPolicy_textBlock}>
        California Business and Professions Code Section 22581 allows California
        residents under the age of 18 who are registered users of online sites,
        services or applications to request and obtain removal of content or
        information they have publicly posted.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        To request removal of such data, and if You are a California resident,
        You can contact Us using the contact information provided below, and
        include the email address associated with Your account.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        Be aware that Your request does not guarantee complete or comprehensive
        removal of content or information posted online and that the law may not
        permit or require removal in certain circumstances.
      </div>
      <h2 className={classes.privacyPolicy_h2}>Children's Privacy</h2>
      <div className={classes.privacyPolicy_textBlock}>
        Our Service does not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from anyone under
        the age of 13. If You are a parent or guardian and You are aware that
        Your child has provided Us with Personal Data, please contact Us. If We
        become aware that We have collected Personal Data from anyone under the
        age of 13 without verification of parental consent, We take steps to
        remove that information from Our servers.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        If We need to rely on consent as a legal basis for processing Your
        information and Your country requires consent from a parent, We may
        require Your parent's consent before We collect and use that
        information.
      </div>
      <h2 className={classes.privacyPolicy_h2}>Links to Other Websites</h2>
      <div className={classes.privacyPolicy_textBlock}>
        Our Service may contain links to other websites that are not operated by
        Us. If You click on a third party link, You will be directed to that
        third party's site. We strongly advise You to review the Privacy Policy
        of every site You visit.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </div>
      <h2 className={classes.privacyPolicy_h2}>Cookies</h2>
      <div className={classes.privacyPolicy_textBlock}>
        Cookies do not typically contain any information that personally
        identifies a user, but personal information that we store about You may
        be linked to the information stored in and obtained from Cookies. For
        further information on how We use, store and keep Your personal data
        secure, see our Privacy Policy.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        We do not store sensitive personal information, such as mailing
        addresses, account passwords, etc. in the Cookies We use.
      </div>
      <h3 className={classes.privacyPolicy_h3}>
        Interpretation and Definitions
      </h3>
      <h4 className={classes.privacyPolicy_h4}>Interpretation</h4>
      <div className={classes.privacyPolicy_textBlock}>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </div>
      <h4 className={classes.privacyPolicy_h4}>Definitions</h4>
      <div className={classes.privacyPolicy_textBlock}>
        For the purposes of this Cookies Policy:
      </div>
      <ul className={classes.privacyPolicy_ul1}>
        <li className={classes.privacyPolicy_li1}>
          <strong>Company</strong> (referred to as either "the Company", "We",
          "Us" or "Our" in this Cookies Policy) refers to Polldock.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>Cookies</strong> means small files that are placed on Your
          computer, mobile device or any other device by a website, containing
          details of Your browsing history on that website among its many uses.
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>Website</strong> refers to Polldock, accessible from
          https://polldock.com
        </li>
        <li className={classes.privacyPolicy_li1}>
          <strong>You</strong> means the individual accessing or using the
          Website, or a company, or any legal entity on behalf of which such
          individual is accessing or using the Website, as applicable.
        </li>
      </ul>
      <h3 className={classes.privacyPolicy_h3}>The use of the Cookies</h3>
      <h4 className={classes.privacyPolicy_h4}>Type of Cookies We Use</h4>
      <div className={classes.privacyPolicy_textBlock}>
        Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
        remain on Your personal computer or mobile device when You go offline,
        while Session Cookies are deleted as soon as You close Your web browser.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        We use both Session and Persistent Cookies for the purposes set out
        below:
      </div>
      <ul className={classes.privacyPolicy_ul1}>
        <li className={classes.privacyPolicy_li2}>
          <div className={classes.privacyPolicy_textLineDiv}>
            <strong>Necessary / Essential Cookies</strong>
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Type: Session Cookies
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Administered by: Us
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Purpose: These Cookies are essential to provide You with services
            available through the Website and to enable You to use some of its
            features. They help to authenticate users and prevent fraudulent use
            of user accounts. Without these Cookies, the services that You have
            asked for cannot be provided, and We only use these Cookies to
            provide You with those services.
          </div>
        </li>
        <li className={classes.privacyPolicy_li2}>
          <div className={classes.privacyPolicy_textLineDiv}>
            <strong>Cookies Policy / Notice Acceptance Cookies</strong>
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Type: Persistent Cookies
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Administered by: Us{" "}
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Purpose: These Cookies identify if users have accepted the use of
            Cookies on the Website.
          </div>
        </li>
        <li className={classes.privacyPolicy_li2}>
          <div className={classes.privacyPolicy_textLineDiv}>
            <strong>Functionality Cookies</strong>
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Type: Persistent Cookies
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Administered by: Us
          </div>
          <div className={classes.privacyPolicy_textLineDiv}>
            Purpose: These Cookies allow us to remember choices You make when
            You use the Website, such as remembering Your login details or
            language preference. The purpose of these Cookies is to provide You
            with a more personal experience and to avoid You having to re-enter
            Your preferences every time You use the Website.
          </div>
        </li>
      </ul>
      <h4 className={classes.privacyPolicy_h4}>
        Your Choices Regarding Cookies
      </h4>
      <div className={classes.privacyPolicy_textBlock}>
        If You prefer to avoid the use of Cookies on the Website, first You must
        disable the use of Cookies in Your browser and then delete the Cookies
        saved in Your browser associated with this website. You may use this
        option for preventing the use of Cookies at any time.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        If You do not accept Our Cookies, You may experience some inconvenience
        in Your use of the Website and some features may not function properly.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        If You'd like to delete Cookies or instruct Your web browser to delete
        or refuse Cookies, please visit the help pages of Your web browser.
      </div>
      <ul className={classes.privacyPolicy_ul1}>
        <li className={classes.privacyPolicy_li1}>
          For the Chrome web browser, please visit this page from Google:{" "}
          <a
            className={classes.privacyPolicy_link}
            href="https://support.google.com/accounts/answer/32050"
          >
            https://support.google.com/accounts/answer/32050
          </a>
        </li>
        <li className={classes.privacyPolicy_li1}>
          For the Internet Explorer web browser, please visit this page from
          Microsoft:{" "}
          <a
            className={classes.privacyPolicy_link}
            href="http://support.microsoft.com/kb/278835"
          >
            http://support.microsoft.com/kb/278835
          </a>
        </li>
        <li className={classes.privacyPolicy_li1}>
          For the Firefox web browser, please visit this page from Mozilla:{" "}
          <a
            className={classes.privacyPolicy_link}
            href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
          >
            https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
          </a>
        </li>
        <li className={classes.privacyPolicy_li1}>
          For the Safari web browser, please visit this page from Apple:{" "}
          <a
            className={classes.privacyPolicy_link}
            href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
          >
            https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
          </a>
        </li>
      </ul>
      <div className={classes.privacyPolicy_textBlock}>
        For any other web browser, please visit Your web browser's official web
        pages.
      </div>
      <h2 className={classes.privacyPolicy_h2}>
        Changes to this Privacy Policy
      </h2>
      <div className={classes.privacyPolicy_textBlock}>
        We may update Our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        We will let You know via email and/or a prominent notice on Our Service,
        prior to the change becoming effective and update the "Last updated"
        date at the top of this Privacy Policy.
      </div>
      <div className={classes.privacyPolicy_textBlock}>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </div>
      <h2 className={classes.privacyPolicy_h2}>Contact Us</h2>
      <div className={classes.privacyPolicy_textBlock}>
        If You have any questions about this Privacy Policy, You can contact us:
      </div>
      <ul className={classes.privacyPolicy_ul1}>
        <li className={classes.privacyPolicy_li1}>
          By email: contact@polldock.com
        </li>
      </ul>
      <div className={classes.privacyPolicy_bottomPadding}></div>
    </div>
  );
}

export default PrivacyPolicy;
