import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { HashRouter } from "react-router-dom";
import { CategoryNameContextProvider } from "./store/category-name-context";
import { FilterSortContextProvider } from "./store/filter-sort-context";
import { DimensionValuesContextProvider } from "./store/dimension-values-context";
import { NewUserResponseContextProvider } from "./store/new-user-response-context";
import { UserIdContextProvider } from "./store/user-id-context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <CategoryNameContextProvider>
    <FilterSortContextProvider>
      <DimensionValuesContextProvider>
        <NewUserResponseContextProvider>
          <UserIdContextProvider>
            <HashRouter>
              <App />
            </HashRouter>
          </UserIdContextProvider>
        </NewUserResponseContextProvider>
      </DimensionValuesContextProvider>
    </FilterSortContextProvider>
  </CategoryNameContextProvider>
);
