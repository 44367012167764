import PrivacyPolicy from "../components/PrivacyPolicy";

import classes from "./PrivacyPolicyPage.module.css"

function PrivacyPolicyPage() {
  //
  return (
    <div>
      <h1 className={classes.privacyPolicyPage_h1}>Privacy Policy</h1>
      <div className={classes.privacyPolicyPage_underline}></div>
      <PrivacyPolicy />
    </div>
  );
}

export default PrivacyPolicyPage;
