import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Turnstile } from "@marsidev/react-turnstile";
import OptionItem from "../components/OptionItem";
import { getCookie } from "../components/CsrfToken";
import CsrfToken from "../components/CsrfToken";
import AnsweredQuestionReroute from "../components/AnsweredQuestionReroute";

import classes from "./AnswerQuestionPage.module.css";

function AnswerQuestionPage(props) {
  //
  // Get the question_id from the URL.
  let { q } = useParams();

  // Set a useState() to track whether the page is loading.
  const [isLoading, setIsLoading] = useState(true);

  // Create useRef() instances for the form and the Turnstile widget.
  const answerQuestionFormRef = useRef();
  const turnstileWidgetRef = useRef();

  // Define a useState() to track the status of the Turnstile widget.
  const [turnstileStatus, setTurnstileStatus] = useState();

  // Define a useState() to track whether the user has responded to the
  //   question.
  const [userHasAnswered, setUserHasAnswered] = useState();

  // Define a useState() to track whether userResponse has been queried upon
  //   loading of the page.
  const [userResponseLoaded, setUserResponseLoaded] = useState(false);

  // Define a useState() to track whether questions and options have been quried
  //   upon loading of the page.
  const [questionLoaded, setQuestionLoaded] = useState(false);

  // Initialize an object to hold the question's ID and text.
  const [question, setQuestion] = useState({});

  // Initialize an array to hold options and their IDs.
  const [options, setOptions] = useState([]);

  // Set a useNavigate() constant.
  const navigate = useNavigate();

  // Get the CSRF token from Django's csrf_token cookie.
  const csrf_token = getCookie("csrftoken");

  // Call useEffect() to enable a chunk of code that will query the user's
  //   response to the question of focus.
  useEffect(() => {
    fetch(
      "https://prodbe.polldock.com/api/userResponse/" + q + "/" + props.user_id + "/"
      // "http://localhost:8000/api/userResponse/" + q + "/" + props.user_id + "/"
    )
      .then((response) => {
        return response.json();
      })
      .then((dataJson) => {
        if (dataJson.length === 0) {
          setUserHasAnswered(false);
        } else {
          setUserHasAnswered(true);
        }
        setUserResponseLoaded(true);
      });
  }, [q, props.user_id]);

  // fetch() the chosen question's id, question_text, option_id, and option_text
  //   values. Put the question's id and question_text into an object.
  //   Iteratively push() each option's id and text, paired as an object, into
  //   an array.
  useEffect(() => {
    fetch("https://prodbe.polldock.com/api/answerOptions/" + q + "/")
    // fetch("http://localhost:8000/api/answerOptions/" + q + "/")
      .then(
        (response) => {
          return response.json();
        },
        () => {
          navigate("/error/", { replace: true });
        }
      )
      .then(
        (dataJson) => {
          const optionsArrayComprehensive = dataJson;
          optionsArrayComprehensive.sort((a, b) => a.option_id - b.option_id);
          const questionObject = {
            question_id: dataJson[0].id,
            question_text: dataJson[0].question_text,
          };
          const optionsArray = [];
          for (const element of optionsArrayComprehensive) {
            optionsArray.push({
              option_id: element.option_id,
              option_text: element.option_text,
            });
          }
          const questionOptionsResponse = {
            questionObject: questionObject,
            optionsArray: optionsArray,
          };
          return questionOptionsResponse;
        },
        () => {
          navigate("/error/", { replace: true });
        }
      )
      .then(
        (qor) => {
          setQuestion(qor.questionObject);
          setOptions(qor.optionsArray);
          setQuestionLoaded(true);
        },
        () => {
          navigate("/error/", { replace: true });
        }
      );
  }, [q, navigate]);

  // Call useEffect() to update isLoading() to false when userResponseLoaded
  //   and questionLoaded both become true.
  useEffect(() => {
    if (userResponseLoaded && questionLoaded) {
      setIsLoading(false);
    }
  }, [userResponseLoaded, questionLoaded]);

  // Set a useState() constant to track the user's current selection.
  const [currentSelection, setCurrentSelection] = useState(null);

  // Define a handler function that checks if the user has selected an answer
  //   and then posts the response to the database once the user clicks the
  //   submit button, and, finally, navigates to the question's results page.
  function submitAnswerHandler(event) {
    //
    // Prevent the default browser behavior. This is needed to prevent the
    //   browser from reloading upon submit.
    event.preventDefault();

    // Get the currentSelection value at the time of submission.
    const answer = currentSelection;

    // Get the Turnstile token value.
    const formData = new FormData(answerQuestionFormRef.current);
    const token = formData.get("cf-turnstile-response");

    // Remove the Turnstile widget.
    turnstileWidgetRef.current?.remove();

    // If there is no answer, alert the user. Otherwise, post the response to
    //   the database and navigate to the results page.
    if (answer === null) {
      alert("Please select an answer!");
    } else {
      //
      // Call the view to post the user's response to the database.
      fetch(
        "https://prodbe.polldock.com/api/newResponse/" +
        // "http://localhost:8000/api/newResponse/" +
          props.user_id +
          "/" +
          q +
          "/" +
          answer +
          "/" +
          token,
        {
          method: "POST",
          headers: { "X-CSRFToken": csrf_token },
        }
      ).then(
        (response) => {
          //
          // If the fetch() returns successfully, navigate to the question's
          //   results page. Otherwise, navigate to the generic error page.
          const statusCode = response.status;
          if (statusCode === 200) {
            navigate("/question-results/" + q + "/");
          } else {
            navigate("/error/", { replace: true });
          }
        },
        () => {
          navigate("/error/", { replace: true });
        }
      );
    }
  }

  // Define a handler function that sets the value of currentSelection based on
  //   which opiton <div> the user clicks on.
  const optionClickHandler = (optionId) => {
    const selection = optionId;
    setCurrentSelection(selection);
  };

  if (isLoading) {
    return (
      <section>
        <p>Loading...</p>
      </section>
    );
  }

  // If the user has answered the question, load the AnsweredQuestionReroute
  //   component to reroute to the corresponding QuestionResultsPage. Otherwise,
  //   load the response options and responding mechanism components.
  if (userHasAnswered) {
    return <AnsweredQuestionReroute q={q} />;
  } else {
    return (
      <div>
        <h1 className={classes.answerQuestionPage_h1}>
          {question.question_text}
        </h1>
        <div className={classes.answerQuestionPage_underline}></div>
        <form
          id="answerQuestionForm"
          method="POST"
          onSubmit={submitAnswerHandler}
          ref={answerQuestionFormRef}
        >
          <CsrfToken />
          <div>
            <ul className={classes.answerQuestionPage_ul}>
              {options.map((option) => (
                <OptionItem
                  key={option.option_id}
                  optionId={option.option_id}
                  optionText={option.option_text}
                  optionClickHandler={optionClickHandler}
                  selected={option.option_id === currentSelection} ////
                />
              ))}
            </ul>
          </div>
          {turnstileStatus === undefined ? (
            <div className={classes.answerQuestionPage_submitDiv}></div>
          ) : (
            <div className={classes.answerQuestionPage_submitDiv}>
              <button className={classes.answerQuestionPage_submit}>
                Submit
              </button>
            </div>
          )}
          <div className={classes.answerQuestionPage_turnstileContainer}>
            <Turnstile
              siteKey="0x4AAAAAAAI6m3wcWooJSSYH"
              ref={turnstileWidgetRef}
              onError={() => setTurnstileStatus("error")}
              onExpire={() => setTurnstileStatus("expired")}
              onSuccess={() => setTurnstileStatus("solved")}
            />
          </div>
        </form>
      </div>
    );
  }
}

export default AnswerQuestionPage;
