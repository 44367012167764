import { createContext, useState, useEffect } from "react";

const CategoryNameContext = createContext({
  categoriesTechnical: [],
  categoriesMapping: {},
});

export function CategoryNameContextProvider(props) {
  const [categoriesTech, setCategoriesTech] = useState([]);
  const [categoriesMap, setCategoriesMap] = useState({});

  useEffect(() => {
    fetch("https://prodbe.polldock.com/api/categoriesOrdered/")
    // fetch("http://localhost:8000/api/categoriesOrdered/")
      .then((response) => {
        return response.json();
      })
      .then((dataJson) => {
        const categoriesArray = dataJson;
        categoriesArray.sort((a, b) => a.display_order - b.display_order);
        const categoriesTechArray = [];
        const categoriesMapObject = {};
        for (const element of categoriesArray) {
          categoriesTechArray.push(element["category_technical"]);
          categoriesMapObject[element["category_technical"]] = {
            category_display: element["category_display"],
          };
        }
        const categoriesResponse = {
          categoriesTechArray: categoriesTechArray,
          categoriesMapObject: categoriesMapObject,
        };
        return categoriesResponse;
      }).then((cr) => {
        setCategoriesTech(cr.categoriesTechArray);
        setCategoriesMap(cr.categoriesMapObject);
      });
  }, []);

  const context = {
    categoriesTechnical: categoriesTech,
    categoriesMapping: categoriesMap,
  };

  return (
    <CategoryNameContext.Provider value={context}>
      {props.children}
    </CategoryNameContext.Provider>
  );
}

export default CategoryNameContext;
