import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function AnsweredQuestionReroute(props) {
    //
    // Set a useNavigate() constant.
    const navigate = useNavigate();
  
    // Call useEffect() to run each time the component is loaded.
    useEffect(() => {
      //
      // Navigate to the question's question results page.
      navigate("/question-results/" + props.q + "/");
    });
  
    // Return nothing.
    return(null);
  }
  
  export default AnsweredQuestionReroute;
  