import { useState, useEffect } from "react";

import classes from "./FilterResultsForm.module.css";

function FilterResultsForm(props) {
  //
  // Define useState() instances to track which decade and location options are
  //   checked (default to all).
  const [decadeChecked, setDecadeChecked] = useState(props.decadeOptions);
  const [locationChecked, setLocationChecked] = useState(props.locationOptions);

  // Define useState() instances to track whether all decade and location
  //   options should be checked or unchecked by default. These will be used in
  //   a function to toggle all options.
  const [decadeAllChecked, setDecadeAllChecked] = useState(true);
  const [locationAllChecked, setLocationAllChecked] = useState(true);

  // Define a handler functions for the decade and location input checkbox sets.
  //   These functions will toggle whether all options should be checked or
  //   unchecked.
  const decadeToggleAllHandler = () => {
    //
    // Set a constant that is the inverse of decadeAllChecked.
    const dacInv = !decadeAllChecked;

    // If dacInv is true, set decadeChecked to props.decadeOptions. If dacInv is
    //   false, set it to [].
    if (dacInv) {
      setDecadeChecked(props.decadeOptions);
    } else if (!dacInv) {
      setDecadeChecked([]);
    }

    // Set decadeAllChecked to dacInv.
    setDecadeAllChecked(dacInv);
  };
  const locationToggleAllHandler = () => {
    //
    // Set a constant that is the inverse of locationAllChecked.
    const lacInv = !locationAllChecked;

    // If lacInv is true, set locationChecked to props.locationOptions. If
    //   lacInv is false, set it to [].
    if (lacInv) {
      setLocationChecked(props.locationOptions);
    } else if (!lacInv) {
      setLocationChecked([]);
    }

    // Set locationAllChecked to lacInv.
    setLocationAllChecked(lacInv);
  };

  // Define handler functions to update decadeOptionsObject and
  //   locationOptionsObject values when a single checkbox item is clicked.
  const decadeClickUpdateHandler = (event) => {
    //
    // Set constants to get the current checkbox value and whether it is
    //   checked.
    const option = event.target.value;
    const checked = event.target.checked;

    // If the checkbox is checked, add the option value to decadeChecked. If the
    //   checkbox is not checked, remove the option value from decadeChecked.
    if (checked) {
      setDecadeChecked(decadeChecked.concat(option));
    } else if (!checked) {
      setDecadeChecked(decadeChecked.filter((element) => element !== option));
    }
  };
  function locationClickUpdateHandler(event) {
    //
    // Set constants to get the current checkbox value and whether it is
    //   checked.
    const option = event.target.value;
    const checked = event.target.checked;

    // If the checkbox is checked, add the option value to locationChecked. If
    //   the checkbox is not checked, remove the option value from
    //   locationChecked.
    if (checked) {
      setLocationChecked(locationChecked.concat(option));
    } else if (!checked) {
      setLocationChecked(
        locationChecked.filter((element) => element !== option)
      );
    }
  }

  // Call useEffect() to run the data pre-filtering function from the parent
  //   component any time decadeChecked changes.
  useEffect(() => {
    props.decadeCheckedUpdateHandler(decadeChecked);
  });

  // Call useEffect() to run the data pre-filtering function from the parent
  //   component any time locationChecked changes.
  useEffect(() => {
    props.locationCheckedUpdateHandler(locationChecked);
  });

  // Define a variable that will hold the sorted and formatted decaded options.
  var decadeOptionsSorted = props.decadeOptions;

  // Sort decadeOptionsSorted so that the value starting with "pre" comes first.
  decadeOptionsSorted = decadeOptionsSorted.sort(function (a, b) {
    if (a.substring(0, 3) === "pre" && b.substring(0, 3) !== "pre") {
      return -1;
    } else if (a.substring(0, 3) !== "pre" && b.substring(0, 3) === "pre") {
      return 1;
    } else {
      return 0;
    }
  });

  // Return an HTML form with checkbox fields for each filtering dimension.
  return (
    <div className={classes.filterResultsForm_containerDiv}>
      <div className={classes.filterResultsForm_checkboxesDiv}>
        <div className={classes.filterResultsForm_checkboxesHeader}>Sex</div>
        <div>
          {props.sexOptions.map((option) => (
            <div key={option}>
              <input
                id={option}
                className={classes.filterResultsForm_checkboxInput}
                type="checkbox"
                value={option}
                defaultChecked={true}
                onChange={props.sexCheckedUpdateHandler}
              />
              <label htmlFor={option}>{option}</label>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.filterResultsForm_checkboxesDiv}>
        <div className={classes.filterResultsForm_checkboxesHeader}>
          Birth decade
        </div>
        <div className={classes.filterResultsForm_checkboxesContainer_decade}>
          {decadeOptionsSorted.map((option) => (
            <div key={option}>
              <input
                id={option}
                className={classes.filterResultsForm_checkboxInput}
                type="checkbox"
                value={option}
                checked={decadeChecked.includes(option)}
                onChange={decadeClickUpdateHandler}
              />
              <label htmlFor={option}>{option}</label>
            </div>
          ))}
        </div>
        <button
          className={classes.filterResultsForm_toggleAllButton}
          onClick={decadeToggleAllHandler}
        >
          Toggle all
        </button>
      </div>
      <div className={classes.filterResultsForm_checkboxesDiv}>
        <div className={classes.filterResultsForm_checkboxesHeader}>
          Location
        </div>
        <div className={classes.filterResultsForm_checkboxesContainer_location}>
          {props.locationOptions.map((option) => (
            <div key={option}>
              <input
                id={option}
                className={classes.filterResultsForm_checkboxInput}
                type="checkbox"
                value={option}
                checked={locationChecked.includes(option)}
                onChange={locationClickUpdateHandler}
              />
              <label htmlFor={option}>{option}</label>
            </div>
          ))}
        </div>
        <button
          className={classes.filterResultsForm_toggleAllButton}
          onClick={locationToggleAllHandler}
        >
          Toggle all
        </button>
      </div>
    </div>
  );
}

export default FilterResultsForm;
