import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RandomQuestionResultsTransition from "../components/RandomQuestionResultsTransition";

function RandomQuestionPage(props) {
  //
  // Set a useNavigate() constant.
  const navigate = useNavigate();

  // Create a useState() to track whether the page is loading.
  const [isLoading, setIsLoading] = useState(true);

  // Create a useState() to track the retrieved data.
  const [randomQuestion, setRandomQuestion] = useState();

  // Store the props.user_id in a constant.
  const userId = props.user_id;

  // Call useEffect() to run the view that obtains the random question.
  useEffect(() => {
    //
    // Call fetch() on the view that gets a random question that the user has
    //   not answered.
    fetch("https://prodbe.polldock.com/api/randomQuestion/" + userId + "/")
    // fetch("http://localhost:8000/api/randomQuestion/" + userId + "/")
      .then(
        (response) => {
          //
          // Convert the returned data to JSON.
          return response.json();
        },
        () => {
          navigate("/error/", { replace: true });
        }
      )
      .then(
        (dataJson) => {
          //
          // Store the data in a constant.
          const rq = dataJson;

          // Get the question ID value from the returned data. If the data array
          //   is empty, return -1.
          if (rq.length === 0) {
            setRandomQuestion(-1);
          } else {
            setRandomQuestion(rq[0].question_id);
          }

          // Set isLoading to false.
          setIsLoading(false);
        },
        () => {
          navigate("/error/", { replace: true });
        }
      );
  }, [userId, navigate]);

  // If isLoading is true, return a loading message.
  if (isLoading) {
    return (
      <section>
        <p>Loading...</p>
      </section>
    );
  }

  // If randomQuestion is -1, return a notification to the user that he/she has
  //   already answered all questions.
  if (randomQuestion === -1) {
    return (
      <div>
        <p>
          It looks like you have already answered all of our questions! Please
          check back soon as new questions are always being added. In the
          meantime, go ahead and browse results for existing questions.
        </p>
      </div>
    );
  }

  return (
    <div>
      <RandomQuestionResultsTransition q={randomQuestion} />
    </div>
  );
}

export default RandomQuestionPage;
